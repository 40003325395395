import React, { useEffect, useState } from "react";
import {
	Autocomplete,
	TextField,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	IconButton,
	Typography,
} from "@mui/material";
import { DOMAIN_URL, getAuthHeaders } from "utils/config";
import { Edit, Save } from "@mui/icons-material";
import Cookies from "js-cookie";

const GpCommencement = () => {
	const [tpList, setTpList] = useState([]);
	const [selectedTp, setSelectedTp] = useState(null);
	const [commencementData, setCommencementData] = useState([]);
	const [editingGpId, setEditingGpId] = useState(null); // Track the currently editing GP ID
	const [tableSearchTerm, setTableSearchTerm] = useState(""); // New state for table search term

	const fetchCurrentRole = async () => {
		const roleId = Cookies.get("role_id");
		if (!roleId) return null;
		try {
			const response = await fetch(`${DOMAIN_URL}/user-roles`, {
				method: "GET",
				headers: getAuthHeaders(),
			});

			if (!response.ok) throw new Error("Failed to fetch user roles");

			const data = await response.json();
			if (data.status && Array.isArray(data.results)) {
				return data.results.find((role) => role.role_id === roleId) || null;
			}
		} catch (error) {
			console.error("Error fetching current role:", error);
		}
		return null;
	};

	const fetchTpList = async (zp_id = "") => {
		try {
			const response = await fetch(
				`${DOMAIN_URL}/tp-list${zp_id ? "/" + zp_id : ""}`,
				{
					method: "GET",
					headers: getAuthHeaders(),
				}
			);
			if (!response.ok) {
				throw new Error("Network response was not ok");
			}

			const data = await response.json();
			if (data.status) {
				setTpList(data.results);
			}
		} catch (error) {
			console.error("Error fetching job cards:", error);
		}
	};

	useEffect(() => {
		const initializeData = async () => {
			const role = await fetchCurrentRole();

			if (role && role.zp_id) {
				fetchTpList(role.zp_id);
				return;
			}

			if (role && role.tp_id) {
				// Fetch commencement data using the tp_id from the role
				const response = await fetch(
					`${DOMAIN_URL}/gp-commencement/${role.tp_id}`,
					{
						method: "GET",
						headers: getAuthHeaders(),
					}
				);
				if (response.ok) {
					const data = await response.json();
					if (data.status) {
						setCommencementData(data.results);
						setSelectedTp({ tp_id: role.tp_id, tp_name: role.tp_name }); // Set the selected TP
					}
				} else {
					console.error("Failed to fetch commencement data");
				}
			} else {
				fetchTpList(); // Fallback to fetching the TP list
			}
		};

		initializeData();
	}, []);

	const handleTpSelect = async (event, value) => {
		if (!tpList.find((tp) => tp.tp_id === value?.tp_id)) {
			// If the selected value doesn't match any in tpList, reset to null
			setSelectedTp(null);
			return;
		}

		setSelectedTp(value || null);

		if (value) {
			const response = await fetch(
				`${DOMAIN_URL}/gp-commencement/${value.tp_id}`,
				{
					method: "GET",
					headers: getAuthHeaders(),
				}
			);
			if (!response.ok) {
				throw new Error("Network response was not ok");
			}

			const data = await response.json();
			if (data.status) {
				setCommencementData(data.results);
			}
		} else {
			setCommencementData([]);
		}
	};

	const handleDateEdit = (gp_id, date) => {
		// Update the commencement date in the state
		setCommencementData((prevData) =>
			prevData.map((gp) =>
				gp.gp_id === gp_id ? { ...gp, commencement_date: date } : gp
			)
		);
	};

	const filteredCommencementData = commencementData.filter((gp) =>
		gp.gp_name.toLowerCase().includes(tableSearchTerm.toLowerCase())
	);

	const handleTableSearch = (event) => {
		setTableSearchTerm(event.target.value); // Update table search term
	};

	const handleSaveCommencementDate = async (gp_id, commencement_date) => {
		try {
			const url = `${DOMAIN_URL}/gp-commencement-update`;
			const inputData = JSON.stringify({ gp_id, commencement_date });

			const params = new URLSearchParams();
			params.append("input_data", inputData);

			const response = await fetch(url, {
				method: "POST",
				headers: getAuthHeaders(),
				body: params,
			});

			if (!response.ok) {
				const errorText = await response.text();
				console.error("Response error:", errorText);
				throw new Error(
					`HTTP error! status: ${response.status}, message: ${errorText}`
				);
			}

			const data = await response.json();
			if (!data.status) {
				throw new Error("Failed to update commencement date");
			}

			// Optionally, you can refresh the data or show a success message here
		} catch (error) {
			console.error("Error updating commencement date:", error);
		}
	};

	return (
		<div>
			<div className="mb-4 pt-2 ml-10 xs:ml-10">
				<Typography variant="h6">GP Commencement Data</Typography>
			</div>
			<div className="flex flex-col xs:flex-row justify-between mb-4">
				{tpList.length > 0 &&
					!selectedTp && ( // Show dropdown only if no TP is selected
						<Autocomplete
							className="flex-grow xs:mr-2"
							id="talukPanchayat"
							options={tpList}
							getOptionLabel={(option) => option.tp_name || ""}
							value={
								tpList.find((tp) => tp.tp_id === selectedTp?.tp_id) || null
							}
							onChange={handleTpSelect}
							isOptionEqualToValue={(option, value) =>
								option.tp_id === value.tp_id
							}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Taluk Panchayat"
									fullWidth
									margin="normal"
									required
								/>
							)}
							renderOption={(props, option) => (
								<li {...props} key={`tp-${option.tp_id}`}>
									{option.tp_name}
								</li>
							)}
						/>
					)}
				{selectedTp && ( // Show TP name if a TP is selected
					<Typography className="flex-grow ml-2 mt-4 xs:mr-2" variant="body1">
						<strong>{selectedTp.tp_name}</strong>
					</Typography>
				)}
				<TextField
					className="flex-grow xs:ml-2 w-full sm:w-64 mt-4"
					variant="outlined"
					placeholder="Search Table…"
					value={tableSearchTerm}
					onChange={handleTableSearch}
				/>
			</div>

			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>GP Code</TableCell>
							<TableCell>GP Name</TableCell>
							<TableCell>Budget Year</TableCell>
							<TableCell>Commencement Date</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{filteredCommencementData.map(
							(
								gp // Use filtered data for table
							) => (
								<TableRow key={gp.gp_id}>
									<TableCell>{gp.gp_code}</TableCell>
									<TableCell>{gp.gp_name}</TableCell>
									<TableCell>{gp.budget_year}</TableCell>
									<TableCell>
										<div className="flex items-center">
											{editingGpId === gp.gp_id ? ( // Check if this row is being edited
												<>
													<TextField
														value={gp.commencement_date || ""}
														onChange={(e) =>
															handleDateEdit(gp.gp_id, e.target.value)
														}
														type="date"
														size="small"
														style={{ width: "120px" }}
													/>
													<IconButton
														onClick={() => {
															handleSaveCommencementDate(
																gp.gp_id,
																gp.commencement_date
															); // Call the new function
															setEditingGpId(null); // Reset editing state
														}}
														className="ml-2 btn-icon rounded-full bg-orange-100 hover:bg-orange-600 text-orange-600 hover:text-white"
													>
														<Save />
													</IconButton>
												</>
											) : (
												<>
													{gp.commencement_date}
													<IconButton
														onClick={() => setEditingGpId(gp.gp_id)} // Set the editing GP ID
														className="ml-2 btn-icon rounded-full bg-orange-100 hover:bg-orange-600 text-orange-600 hover:text-white"
													>
														<Edit />
													</IconButton>
												</>
											)}
										</div>
									</TableCell>
								</TableRow>
							)
						)}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
};

export default GpCommencement;
