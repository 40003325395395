import DashboardIcon from "@mui/icons-material/Dashboard";
import { useContext, useEffect, useState } from "react";
import { Link as Link2, useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-scroll";
import { Tooltip } from "@mui/material";
import PanToolIcon from "@mui/icons-material/PanTool";
// import LogoLight from "../assets/images/logo-light.png";
import MainLogo from "../assets/images/main-logo-no-bg.png";
// import LogoDark from "../assets/images/logo-dark.png";
import Typed from "typed.js";

import BackgroudImage from "../assets/images/bg/11.JPG";
import About from "../component/About";
import Services from "../component/Services";
// import Pricing from "../component/Pricing";
// import Blog from "../component/Blog";
import Contact from "../component/Contact";
import Footer from "../component/Footer";
import Portfolio from "../component/Portfolio";
// import Review from "../component/Testimonial";
import UserContext from "contexts/UserContext";
import Header from "component/Header/Header";
import { scroller } from "react-scroll";
import { translations, getCurrentLanguage } from "../utils/translations";

/**
 * Index Component
 */
export default function Index() {
	const currentLanguage = getCurrentLanguage();
	const t = translations[currentLanguage];

	const [isOpen, setMenu] = useState(true);
	window.addEventListener("scroll", windowScroll);
	const { userState } = useContext(UserContext);
	const location = useLocation();
	const navigate = useNavigate();

	const toggleMenu = () => {
		setMenu(!isOpen);
	};

	const typedStrings =
		currentLanguage === "en"
			? ["<b>Individuals</b>", "<b>Community</b>"]
			: ["<b>ವ್ಯಕ್ತಿಗಳಿಗೆ</b>", "<b>ಸಮುದಾಯಕ್ಕೆ</b>"];

	setTimeout(() => {
		new Typed("#typed", {
			strings: typedStrings,
			backDelay: 2000,
			loop: true,
			startDelay: 300,
			typeSpeed: 100,
			backSpeed: 100,
		});
	}, 500);

	/**
	 * Window scroll
	 */
	function windowScroll() {
		const navbar = document.getElementById("navbar");
		if (
			document.body.scrollTop >= 50 ||
			document.documentElement.scrollTop >= 50
		) {
			navbar?.classList.add("is-sticky");
		} else {
			navbar?.classList.remove("is-sticky");
		}
	}

	useEffect(() => {
		if (location.state && location.state.scrollTo) {
			scroller.scrollTo(location.state.scrollTo, {
				duration: 800,
				delay: 0,
				smooth: "easeInOutQuart",
			});
		}
	}, [location]);

	return (
		<>
			<div>
				<Header />
				<section
					style={{ backgroundImage: `url(${BackgroudImage})` }}
					className="py-36 lg:py-64 w-full table relative bg-center bg-cover"
					id="home"
				>
					<div className="absolute inset-0 bg-black opacity-80"></div>
					<div className="container relative">
						<div className="grid grid-cols-1 mt-12">
							<h4 className="text-white lg:text-5xl text-4xl lg:leading-normal leading-normal font-medium mb-7 position-relative">
								{currentLanguage === "en" ? (
									<>
										Providing Brilliant Ideas <br />
										for <span className="text-green-700">100</span> days
										to&nbsp;
										<span
											className="typewrite relative text-type-element"
											id="typed"
											data-period="2000"
											data-type="[Individual, Community]"
										></span>
									</>
								) : (
									<>
										<span
											className="typewrite relative text-type-element"
											id="typed"
											data-period="2000"
											data-type="[ವ್ಯಕ್ತಿಗಳಿಗೆ, ಸಮುದಾಯಕ್ಕೆ]"
										></span>{" "}
										100 ದಿನಗಳ ಕಾಲ ಸುಧಾರಿತ ಆಲೋಚನೆಗಳನ್ನು ನೀಡುವುದು
									</>
								)}
							</h4>

							<p className="text-white opacity-50 mb-0 max-w-2xl text-lg">
								{t.banner_desc}
							</p>

							<div className="relative mt-10">
								<Link2
									to="/raise-demand-work"
									className="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white rounded-md"
								>
									{t.want_a_job}
								</Link2>
							</div>
						</div>
					</div>
				</section>

				{/* About section */}
				<About />

				{/* Service section */}
				<Services />

				{/* Portfolio section */}
				<Portfolio />

				{/* Review section */}
				{/* <Review /> */}

				{/* Pricing section */}
				{/* <Pricing /> */}

				{/* Blog section */}
				{/* <Blog /> */}

				{/* Contact section */}
				<Contact />

				{/* Footer section */}
				<Footer />

				{/* Flying button with tooltip */}
				<Tooltip title={t.want_a_job} arrow placement="left">
					<button
						className="fixed bottom-6 right-6 p-3 bg-blue-500 text-white rounded-full shadow-lg hover:bg-blue-600 transition-all duration-300 z-50"
						onClick={() => navigate("/raise-demand-work-public")}
					>
						<PanToolIcon />
					</button>
				</Tooltip>

				{/* Switcher section */}
				{/* <Switcher /> */}
			</div>
		</>
	);
}
