import React, { FC } from "react";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Button,
} from "@mui/material";

interface ConfirmationDialogProps {
	open: boolean;
	onConfirm: () => void;
	onCancel: () => void;
}

const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
	open,
	onConfirm,
	onCancel,
}) => {
	return (
		<Dialog open={open} onClose={onCancel}>
			<DialogTitle>{"User Not Found"}</DialogTitle>
			<DialogContent>
				<DialogContentText>
					{/* We couldn't find an account with this phone number. Would you like to
					register and proceed to OTP verification? */}
					Please try logging in again using the right credentials
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={onCancel} color="primary">
					Cancel
				</Button>
				{/* <Button onClick={onConfirm} color="primary" autoFocus>
					Continue
				</Button> */}
			</DialogActions>
		</Dialog>
	);
};

export default ConfirmationDialog;
