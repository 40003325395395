import React, { FC } from "react";
import EnterOTPExisting from "./EnterOTPExisting";
import EnterOTPFirstTime from "./EnterOTPFirstTime";
import { Button } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";

interface OTPVerificationProps {
	phoneNumber: string;
	onOTPSuccess: (data: any) => void;
	onFirstTimeOTPSuccess: (success: boolean) => void;
	type: "EXISTING" | "FIRST_TIME";
	onBack: () => void;
	autofilledOtp?: string;
}

const OTPVerification: FC<OTPVerificationProps> = ({
	phoneNumber,
	onOTPSuccess,
	onFirstTimeOTPSuccess,
	type,
	onBack,
	autofilledOtp,
}) => {
	return (
		<>
			{type === "EXISTING" && (
				<EnterOTPExisting
					phoneNumber={phoneNumber}
					onSuccess={onOTPSuccess}
					autofilledOtp={autofilledOtp}
				/>
			)}
			{type === "FIRST_TIME" && (
				<>
					<Button
						onClick={onBack}
						variant="text"
						color="primary"
						className="mb-4"
						startIcon={<ArrowBack />}
					>
						Back
					</Button>
					<EnterOTPFirstTime
						phoneNumber={phoneNumber}
						onSuccess={onFirstTimeOTPSuccess}
					/>
				</>
			)}
		</>
	);
};

export default OTPVerification;
