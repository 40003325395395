import { DOMAIN_URL, getAuthHeaders } from "utils/config";

interface WorkListResponse {
	key: string;
	status: boolean;
	img_path: string;
	results: any[];
}

interface WorkListParams {
	work_sc1_id?: string;
	work_sc2_id?: string;
	work_sc3_id?: string;
	work_sc4_id?: string;
	work_sc5_id?: string;
}

export const fetchWorkList = async (
	workType: string,
	jobCardId: string,
	params: WorkListParams = {}
): Promise<WorkListResponse> => {
	try {
		const queryParams = new URLSearchParams({
			work_type: workType,
			job_card_id: jobCardId,
			work_sc1_id: params.work_sc1_id || "",
			work_sc2_id: params.work_sc2_id || "",
			work_sc3_id: params.work_sc3_id || "",
			work_sc4_id: params.work_sc4_id || "",
			work_sc5_id: params.work_sc5_id || "",
		});

		const response = await fetch(
			`${DOMAIN_URL}/work-list?${queryParams.toString()}`,
			{
				headers: getAuthHeaders(),
			}
		);

		if (!response.ok) {
			const errorText = await response.text();
			console.error("Response error:", errorText);
			throw new Error(
				`HTTP error! status: ${response.status}, message: ${errorText}`
			);
		}

		const data: WorkListResponse = await response.json();
		if (!data.status) {
			console.log("Failed to fetch work list");
		}

		return data;
	} catch (error) {
		console.error("Error fetching work list:", error);
		throw error; // Re-throwing the error for further handling
	}
};
