import React, { useState } from "react";
import Lightbox from "react-18-image-lightbox";
import "react-18-image-lightbox/style.css";
import { Link } from "react-router-dom";
import { translations, getCurrentLanguage } from "../utils/translations";

import Image1 from "../assets/images/portfolio/1.jpg";
import Image2 from "../assets/images/portfolio/2.jpg";
import Image3 from "../assets/images/portfolio/3.jpg";
import Image4 from "../assets/images/portfolio/4.jpg";

import CTABackground from "../assets/images/bg/getstarted.jpeg";

const images = [Image1, Image2, Image3, Image4];

/**
 * Portfolio section
 */
export default function Portfolio() {
	const [photoIndex, setActiveIndex] = useState(0);
	const [isOpen, setOpen] = useState(false);
	const currentLanguage = getCurrentLanguage();
	const t = translations[currentLanguage];

	const projectList = [
		{
			image: Image1,
			title: t.amruth_sarovara,
		},
		{
			image: Image2,
			title: t.jaljeevan_mission,
		},
		{
			image: Image3,
			title: t.jal_sanjeevini,
		},
		{
			image: Image4,
			title: t.grey_water_management,
		},
	];

	return (
		<>
			{/* Project Start  */}
			<section
				className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800 active"
				id="portfolio"
			>
				<div className="container">
					<div className="grid grid-cols-1 pb-8 text-center">
						<h6 className="text-orange-600 text-base font-medium uppercase mb-2">
							{t.portfolio}
						</h6>
						<h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">
							{t.our_schemes}
						</h3>

						<p className="text-slate-400 dark:text-slate-300 max-w-xl mx-auto">
							{t.portfolio_description}
						</p>
					</div>

					<div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 gap-6 mt-8">
						{projectList.map((item, index) => (
							<div
								className="relative rounded-md shadow-sm overflow-hidden group"
								key={index}
							>
								<img
									src={item.image}
									className="group-hover:origin-center group-hover:scale-110 group-hover:rotate-3 transition duration-500"
									alt="workimage"
									style={{
										height: "100%",
									}}
								/>
								<div className="absolute inset-0 group-hover:bg-black opacity-50 transition duration-500 z-0"></div>

								<div className="content">
									<div className="absolute z-10 opacity-0 group-hover:opacity-100 bottom-4 start-4 transition-all duration-500">
										<Link
											to="#"
											className="h6 text-md font-medium text-white hover:text-orange-600 transition duration-500"
										>
											{item.title}
										</Link>
									</div>
								</div>
							</div>
						))}
					</div>
					{isOpen && (
						<Lightbox
							mainSrc={images[photoIndex]}
							nextSrc={images[(photoIndex + 1) % images.length]}
							prevSrc={images[(photoIndex + images.length - 1) % images.length]}
							onCloseRequest={() => setOpen({ isOpen: false })}
							onMovePrevRequest={() =>
								setActiveIndex((photoIndex + images.length - 1) % images.length)
							}
							onMoveNextRequest={() =>
								setActiveIndex((photoIndex + 1) % images.length)
							}
						/>
					)}
				</div>
			</section>
			{/* Project End  */}
			<section
				style={{ backgroundImage: `url(${CTABackground})` }}
				className="py-24 w-full table relative bg-center bg-cover"
			>
				<div className="absolute inset-0 bg-black opacity-80"></div>
				<div className="container relative">
					<div className="grid grid-cols-1 pb-8 text-center">
						<h3 className="mb-6 md:text-3xl text-2xl text-white font-medium">
							{t.convergence_comes_here}
						</h3>

						<p className="text-white opacity-50 max-w-xl mx-auto">
							{t.what_is_convergence}
						</p>

						<div className="relative mt-10">
							<Link
								to="#"
								className="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white rounded-md"
							>
								{t.get_started}
							</Link>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}
