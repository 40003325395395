import React, {
	useState,
	useEffect,
	useContext,
	useCallback,
	useMemo,
	useRef,
} from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { DOMAIN_URL, getAuthHeaders } from "utils/config";
import RoleContext from "contexts/RoleContext";

// Custom hook to listen for cookie changes
const RoleSelect = () => {
	const { selectedRole, setSelectedRole } = useContext(RoleContext);
	const [roles, setRoles] = useState([]);
	const [loading, setLoading] = useState(true);
	const location = useLocation();
	const navigate = useNavigate();

	// Using useRef to store whether cookies are present to prevent multiple rechecks
	const cookiesPresentRef = useRef(
		!!Cookies.get("admin_id") && !!Cookies.get("role_id")
	);

	// Memoizing the roles to prevent unnecessary recalculation on every render
	const memoizedRoles = useMemo(() => {
		return roles.map((role) => (
			<MenuItem key={role.role_id} value={role.role_id} className="text-sm">
				{role.admin_role_name}
			</MenuItem>
		));
	}, [roles]);

	const fetchUserRoles = useCallback(async () => {
		try {
			const response = await fetch(`${DOMAIN_URL}/user-roles`, {
				method: "GET",
				headers: getAuthHeaders(),
			});

			if (response.ok) {
				const data = await response.json();
				if (data.status && Array.isArray(data.results)) {
					setRoles(data.results);

					// Get the role_id from the cookie
					const savedRoleId = Cookies.get("role_id");
					if (savedRoleId) {
						const savedRole = data.results.find(
							(role) => role.role_id === savedRoleId
						);
						if (savedRole) {
							setSelectedRole(savedRole);
						}
					} else if (data.results.length > 0) {
						const defaultRole = data.results[0];
						setSelectedRole(defaultRole);
						Cookies.set("role_id", defaultRole.role_id, { path: "/" });
					}
					return true; // Indicate successful fetch
				}
			}
			return false; // Indicate unsuccessful fetch
		} catch (error) {
			console.error("Error fetching user roles:", error);
			return false; // Indicate unsuccessful fetch
		} finally {
			setLoading(false);
		}
	}, [setSelectedRole]);

	// Trigger fetch only once by using an empty dependency array
	useEffect(() => {
		const initFetch = async () => {
			const success = await fetchUserRoles();
			if (!success) {
				// If the fetch was unsuccessful, refresh the page
				window.location.reload();
			}
		};

		initFetch();
	}, [fetchUserRoles]);

	const handleRoleChange = (event) => {
		const newRoleId = event.target.value;
		const newRole = roles.find((role) => role.role_id === newRoleId);
		if (newRole) {
			setSelectedRole(newRole);
			Cookies.set("role_id", newRole.role_id, { path: "/" });
			navigate("/core/dashboard", { replace: true });
			setTimeout(() => {
				window.location.reload();
			}, 0);
		}
	};

	if (loading || roles.length === 0) {
		// Change the condition to check for zero roles
		return null;
	}

	return (
		<FormControl className="w-full sm:w-48">
			<InputLabel
				id="role-select-label"
				className="text-sm text-gray-400 dark:text-white/70"
				sx={{
					lineHeight: "1rem",
					transform: "translate(14px, 9px) scale(1)",
					"&.Mui-focused, &.MuiInputLabel-shrink": {
						transform: "translate(14px, -9px) scale(0.75)",
					},
				}}
			>
				Role
			</InputLabel>
			<Select
				labelId="role-select-label"
				id="role-select"
				value={selectedRole?.role_id || ""}
				label="Role"
				onChange={handleRoleChange}
				className="h-9 text-sm bg-transparent text-gray-400 dark:text-white/70 border-gray-400 dark:border-white/70"
				sx={{
					"& .MuiSelect-select": {
						paddingTop: "8px",
						paddingBottom: "8px",
					},
					"& .MuiOutlinedInput-notchedOutline": {
						top: "-5px",
						borderColor: "currentColor",
					},
					"&:hover .MuiOutlinedInput-notchedOutline": {
						borderColor: "currentColor",
					},
					"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
						borderColor: "currentColor",
					},
					"& .MuiSvgIcon-root": {
						color: "currentColor",
					},
				}}
				MenuProps={{
					PaperProps: {
						style: { maxHeight: 200 },
						className:
							"bg-white dark:bg-slate-900 text-gray-900 dark:text-white",
					},
				}}
			>
				{memoizedRoles}
			</Select>
		</FormControl>
	);
};

export default React.memo(RoleSelect);
