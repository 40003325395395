import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// Define your MUI theme
const theme = createTheme({
	palette: {
		primary: {
			main: "#FF4D00",
		},
		secondary: {
			main: "#23376d",
		},
		text: {
			primary: "#484848",
			secondary: "#212121",
		},
	},
	typography: {
		fontFamily: `'Open Sans', sans-serif`,
		body1: {
			fontWeight: 400,
			fontSize: "1rem",
		},
		h1: {
			fontSize: "2rem",
			fontWeight: 700,
			marginTop: "2rem",
			color: "#212121",
		},
		h2: {
			fontSize: "2rem",
			fontWeight: 700,
			lineHeight: "2.125rem",
			marginTop: "2.5rem",
			marginBottom: "2.5rem",
			color: "#212121",
		},
		h3: {
			fontSize: "1.1rem",
			fontWeight: 600,
			lineHeight: "1.425rem",
		},
	},
	components: {
		MuiInputLabel: {
			styleOverrides: {
				root: {
					color: "rgb(156, 163, 175)", // Tailwind's gray-400
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					"&:not(.Mui-focused):not(.Mui-error) .MuiOutlinedInput-notchedOutline":
						{
							borderColor: "rgb(209, 213, 219)", // Tailwind's gray-300
						},
				},
			},
		},
	},
});

const container = document.getElementById("root");

if (container) {
	const root = ReactDOM.createRoot(container);
	root.render(
		<React.StrictMode>
			<BrowserRouter>
				<ThemeProvider theme={theme}>
					<App />
				</ThemeProvider>
			</BrowserRouter>
		</React.StrictMode>
	);
}

// Measure performance
reportWebVitals();

// If ResizeObserver exists, observe document.body to avoid the loop error
if (typeof ResizeObserver !== "undefined") {
	const observer = new ResizeObserver(() => {
		// Empty callback to avoid the ResizeObserver error loop
	});
	observer.observe(document.body);
}

// Suppress ResizeObserver loop limit exceeded error globally
const resizeObserverLoopErrRe = /ResizeObserver loop limit exceeded/;
window.addEventListener("error", (event) => {
	if (resizeObserverLoopErrRe.test(event.message)) {
		event.preventDefault(); // Prevent the error from showing in the UI
		console.warn("ResizeObserver loop limit exceeded, but suppressed.");
	}
});

// Optionally suppress the error in console.error to avoid console clutter
// const originalConsoleError = console.error;
// console.error = (...args) => {
// 	if (args[0] && args[0].includes("ResizeObserver loop limit exceeded")) {
// 		return; // Prevent the log of this error in console
// 	}
// 	originalConsoleError.apply(console, args);
// };
