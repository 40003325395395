import {
	isAuthenticated,
	logout,
	removeRegistrationDialogStatus,
	showRegistrationDialog,
} from "utils/auth";

interface UserState {
	isAuthenticated: boolean;
	showRegistrationDialog: boolean;
	deliveryPincode?: string;
}

export const initialState: UserState = {
	isAuthenticated: isAuthenticated(),
	showRegistrationDialog: showRegistrationDialog(),
};

export interface ActionType {
	type:
		| "USER_AUTHENTICATED"
		| "USER_UNAUTHENTICATED"
		| "UPDATE_DELIVERY_LOCATION"
		| "USER_REGISTRATION_DIALOG_CLOSE"
		| "FORCE_RELOAD";
	payload?: {
		isAuthenticated?: boolean;
		deliveryPincode?: string;
		showRegistrationDialog?: boolean;
	};
}

const reducer = (state: UserState, action: ActionType): UserState => {
	switch (action.type) {
		case "USER_AUTHENTICATED":
			return {
				...state,
				isAuthenticated: action.payload?.isAuthenticated ?? true,
				showRegistrationDialog: action.payload?.showRegistrationDialog ?? false,
			};
		case "USER_UNAUTHENTICATED":
			logout();
			return {
				...state,
				isAuthenticated: false,
			};
		case "UPDATE_DELIVERY_LOCATION":
			return {
				...state,
				deliveryPincode: action.payload?.deliveryPincode,
			};
		case "USER_REGISTRATION_DIALOG_CLOSE":
			removeRegistrationDialogStatus();
			return {
				...state,
				showRegistrationDialog: action.payload?.showRegistrationDialog ?? false,
			};
		case "FORCE_RELOAD":
			// Return a new state object to force a re-render
			return { ...state };
		default:
			return state;
	}
};

export default reducer;