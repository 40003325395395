import React, { FC } from "react";
import Register from "./Register";

interface RegistrationFlowProps {
	phoneNumber: string;
	password: string;
	onSuccess: () => void;
}

const RegistrationFlow: FC<RegistrationFlowProps> = ({
	phoneNumber,
	password,
	onSuccess,
}) => {
	return (
		<Register
			phoneNumber={phoneNumber}
			onSuccess={onSuccess}
			password={password}
		/>
	);
};

export default RegistrationFlow;
