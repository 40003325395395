import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DOMAIN_URL, getAuthHeaders } from "utils/config";
import DemandHistoryTable from "./Table";
import { CircularProgress, Box } from "@mui/material";

const DemandHistory = () => {
  const { work_demand_id } = useParams();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchUrl = `${DOMAIN_URL}/demand-work-history/${work_demand_id}`;
        const response = await fetch(fetchUrl, {
          method: "GET",
          headers: getAuthHeaders(),
        });
        if (!response.ok) throw new Error("Network response was not ok");
        const fetchedData = await response.json();
        setData(fetchedData);
      } catch (error) {
        console.error("Error fetching data:", error);
        setData(null);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [work_demand_id]);

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return <DemandHistoryTable data={data} title="Demand History" />;
};

export default DemandHistory;
