import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { isActiveLink } from "utils/linkActiveChecker";
import { getSidebar } from "../api";
import HomeIcon from "@mui/icons-material/Home";
import CheckIcon from "@mui/icons-material/Check";
import DescriptionIcon from "@mui/icons-material/Description";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ListIcon from "@mui/icons-material/List";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import BarChartIcon from "@mui/icons-material/BarChart";
import PersonIcon from "@mui/icons-material/Person";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Cookies from "js-cookie";

const iconMap = {
	"glyphicon glyphicon-home": HomeIcon,
	"glyphicon glyphicon-check": CheckIcon,
	"glyphicon glyphicon-file": DescriptionIcon,
	"glyphicon glyphicon-usd": AttachMoneyIcon,
	"glyphicon glyphicon-th-list": ListIcon,
	"glyphicon glyphicon-tags": LocalOfferIcon,
	"glyphicon glyphicon-flash": FlashOnIcon,
	"glyphicon glyphicon-report": BarChartIcon,
	"ti-user": PersonIcon,
};

const Sidebar = ({ onLinkClick }) => {
	// Accept onLinkClick as a prop
	const location = useLocation();
	const navigate = useNavigate();
	const [openMenus, setOpenMenus] = useState({});
	const [sidebarData, setSidebarData] = useState(null);

	useEffect(() => {
		const fetchSidebarData = async () => {
			try {
				const data = await getSidebar();
				setSidebarData(data);
			} catch (error) {
				console.error("Error fetching sidebar data:", error);
			}
		};

		if (Cookies.get("admin_id") && Cookies.get("role_id")) {
			fetchSidebarData();
		}
	}, [location]);

	const handleNavigation = (path) => {
		navigate(path);
		window.scrollTo(0, 0);
		if (onLinkClick) onLinkClick(); // Call the onLinkClick function to close the drawer
	};

	const handleToggle = (id) => {
		setOpenMenus((prev) => ({
			...prev,
			[id]: !prev[id],
		}));
	};

	if (!sidebarData) {
		return null; // or a loading spinner
	}

	return (
		<div className="shadow-md bg-slate-50 min-w-[180px] sm:min-w-[200px] md:min-w-[220px] lg:min-w-[320px] mt-6 sticky top-[80px]">
			<ul>
				{sidebarData.map((item) => {
					const IconComponent = iconMap[item.sidebar_icon] || HomeIcon;
					return (
						<li key={item.sidebar_id}>
							<div
								className={`flex items-center p-3 cursor-pointer ${
									openMenus[item.sidebar_id]
										? "bg-gray-200"
										: "hover:bg-gray-300"
								}`}
								onClick={() => {
									handleToggle(item.sidebar_id);
									if (item.sidebar_link) handleNavigation(item.sidebar_link);
								}}
							>
								<IconComponent className="mr-3" />
								<span className="mr-3">{item.sidebar_name}</span>
								{item.menu_details && item.menu_details.length > 0 && (
									<span className="ml-auto">
										{openMenus[item.sidebar_id] ? (
											<ExpandLessIcon />
										) : (
											<ExpandMoreIcon />
										)}
									</span>
								)}
							</div>
							{item.menu_details && item.menu_details.length > 0 && (
								<ul
									className={`pl-6 ${
										openMenus[item.sidebar_id] ? "block" : "hidden"
									}`}
								>
									{item.menu_details.map((subItem) => {
										const SubIconComponent =
											iconMap[subItem.sidebar_menu_icon] || HomeIcon;
										return (
											<li
												key={subItem.sidebar_menu_id}
												className={`p-2 cursor-pointer ${
													isActiveLink(
														`/core/${subItem.sidebar_menu_link}`,
														location.pathname
													)
														? "text-orange-600"
														: "hover:bg-orange-100"
												}`}
												onClick={() =>
													handleNavigation(subItem.sidebar_menu_link)
												}
											>
												<div className="flex items-center">
													<SubIconComponent className="mr-3" />
													<span>{subItem.sidebar_menu_name}</span>
												</div>
											</li>
										);
									})}
								</ul>
							)}
						</li>
					);
				})}
			</ul>
		</div>
	);
};

export default Sidebar;
