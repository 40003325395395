import React, { useState, useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { DOMAIN_URL, getAuthHeaders } from "utils/config";

const JobCardSelector = ({ villageId, onJobCardSelect, selectedJobCard }) => {
	const [jobCards, setJobCards] = useState([]);
	const [selectedCard, setSelectedCard] = useState(null);

	useEffect(() => {
		if (villageId) {
			fetchJobCards(villageId);
		}
	}, [villageId]);

	useEffect(() => {
		if (selectedJobCard) {
			setSelectedCard(selectedJobCard);
		}
	}, [selectedJobCard]);

	const fetchJobCards = async (villageId) => {
		try {
			const response = await fetch(
				`${DOMAIN_URL}/jobcard-list/VL/${villageId}`,
				{
					method: "GET",
					headers: getAuthHeaders(),
				}
			);

			if (!response.ok) {
				throw new Error("Network response was not ok");
			}

			const data = await response.json();
			if (data.status) {
				setJobCards(data.results);
			}
		} catch (error) {
			console.error("Error fetching job cards:", error);
		}
	};

	return (
		<Autocomplete
			id="jobCard"
			options={jobCards}
			value={selectedCard}
			getOptionLabel={(option) => `${option.reg_no} - ${option.full_name}`}
			onChange={(event, newValue) => {
				setSelectedCard(newValue);
				onJobCardSelect(newValue);
			}}
			renderInput={(params) => (
				<TextField {...params} label="Job Card" fullWidth margin="normal" />
			)}
			renderOption={(props, option) => (
				<li {...props} key={`jobCard-${option.job_card_id}`}>
					{option.reg_no} - {option.full_name}
				</li>
			)}
			isOptionEqualToValue={(option, value) =>
				option.job_card_id === value.job_card_id
			}
		/>
	);
};

export default JobCardSelector;
