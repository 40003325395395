import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Snackbar,
  Alert,
  IconButton,
} from "@mui/material";
import { DOMAIN_URL, getAuthHeaders } from "utils/config";
import { Edit, Save } from "@mui/icons-material";

const ActionPlanPriority = ({ data, onPriorityUpdate }) => {
  const [editingId, setEditingId] = useState(null);
  const [editedPriority, setEditedPriority] = useState("");
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const handleEdit = (id, currentPriority) => {
    setEditingId(id);
    setEditedPriority(currentPriority);
  };

  const handleSave = async (workDemandId) => {
    try {
      const inputData = JSON.stringify({
        work_demand_id: parseInt(workDemandId),
        priority: parseInt(editedPriority),
      });
      const formData = new URLSearchParams();
      formData.append("input_data", inputData);

      const response = await fetch(`${DOMAIN_URL}/update-work-priority`, {
        method: "POST",
        headers: getAuthHeaders(),
        body: formData,
      });

      const result = await response.json();

      if (result.status) {
        setSnackbar({
          open: true,
          message: result.message,
          severity: "success",
        });
        setEditingId(null);
        onPriorityUpdate(); // Trigger a refresh in the parent component
      } else {
        throw new Error(result.message);
      }
    } catch (error) {
      console.error("Error updating priority:", error);
      setSnackbar({
        open: true,
        message: error.message || "Failed to update priority",
        severity: "error",
      });
    }
  };

  const visibleColumns = data.results[0]
    ? Object.keys(data.results[0]).filter((key) => !key.includes("_id"))
    : [];

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {visibleColumns.map((key) => (
                <TableCell
                  key={key}
                  className="bg-slate-100 text-dark-600 font-semibold align-top"
                >
                  {key.replaceAll("_", " ").toUpperCase()}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.results.map((row) => (
              <TableRow key={row.work_demand_id}>
                {visibleColumns.map((key) => (
                  <TableCell key={key}>
                    {key === "priority" ? (
                      editingId === row.work_demand_id ? (
                        <div className="flex items-center">
                          <TextField
                            value={editedPriority}
                            onChange={(e) => setEditedPriority(e.target.value)}
                            type="number"
                            size="small"
                            style={{ width: "80px" }} // Increased width for better visibility
                          />
                          <IconButton
                            onClick={() => handleSave(row.work_demand_id)}
                            className="ml-2 btn-icon rounded-full bg-orange-100 hover:bg-orange-600 text-orange-600 hover:text-white"
                          >
                            <Save />
                          </IconButton>
                        </div>
                      ) : (
                        <div className="flex items-center">
                          {row[key]}
                          <IconButton
                            onClick={() =>
                              handleEdit(row.work_demand_id, row[key])
                            }
                            className="ml-2 btn-icon rounded-full bg-orange-100 hover:bg-orange-600 text-orange-600 hover:text-white"
                          >
                            <Edit />
                          </IconButton>
                        </div>
                      )
                    ) : (
                      row[key]
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{ marginTop: "80px" }}
      >
        <Alert severity={snackbar.severity} sx={{ width: "100%" }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ActionPlanPriority;
