import { Box, CssBaseline, IconButton, Drawer } from "@mui/material";
import Footer from "component/Footer";
import Sidebar from "./common/Sidebar";
import Header from "component/Header/CommonHeader";
import ContentArea from "./common/ContentArea";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import GpBudget from "./GpBudgetAndCommencement/GpBudget";
import GpCommencement from "./GpBudgetAndCommencement/GpCommencement";
import MenuIcon from "@mui/icons-material/Menu"; // Import Menu icon
import ActionPlanDraftList from "./common/ActionPlanDraftList/ActionPlanDraftList";
import DemandHistory from "./DemandHistory/DemandHistory";
import PremeasurementInfo from "./PremeasurementInfo";

const Index = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const [drawerOpen, setDrawerOpen] = useState(false); // State to control drawer open/close

	useEffect(() => {
		if (location.pathname === "/core") {
			navigate("/core/dashboard");
		}
	}, [location, navigate]);

	const toggleDrawer = () => {
		setDrawerOpen(!drawerOpen); // Toggle drawer state
	};

	return (
		<Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
			<CssBaseline />
			<Header />

			<Box sx={{ display: "flex", flexGrow: 1, position: "relative" }}>
				{/* Button to open the drawer on mobile */}
				<IconButton
					sx={{
						display: { xs: "block", md: "none" },
						position: "absolute",
						left: 16,
						top: 16, // Adjust this value as needed
						zIndex: 1000,
					}}
					onClick={toggleDrawer}
				>
					<MenuIcon />
				</IconButton>

				{/* Drawer for the sidebar on mobile */}
				<Drawer
					anchor="left"
					open={drawerOpen}
					onClose={toggleDrawer}
					sx={{
						"& .MuiDrawer-paper": {
							width: 240, // Width of the sidebar
						},
					}}
				>
					<Sidebar onLinkClick={toggleDrawer} />{" "}
					{/* Pass toggleDrawer as a prop */}
				</Drawer>

				{/* Sidebar for larger screens */}
				<Box
					sx={{ display: { xs: "none", md: "block" } }} // Hide on mobile, show on md and larger
				>
					<Sidebar />
				</Box>

				<Box
					component="main"
					sx={{
						flexGrow: 1,
						p: { xs: 2, sm: 3 },
						overflowX: "auto",
						width: "100%",
					}}
				>
					<Routes>
						<Route path="/dashboard" element={<DefaultDashboardContent />} />
						<Route path="/*" element={<ContentArea />} />
						<Route path="/gp-budget" element={<GpBudget />} />
						<Route path="/gp-commencement" element={<GpCommencement />} />
						<Route
							path="/actionplan-draft-list"
							element={<ActionPlanDraftList />}
						/>
						<Route
							path="/approved-demand-list/:work_demand_id"
							element={<DemandHistory />}
						/>
						<Route
							path="/rejected-demand-list/:work_demand_id"
							element={<DemandHistory />}
						/>
						<Route
							path="/premeasurement-data/:work_id"
							element={<PremeasurementInfo />}
						/>

						{/* Add more nested routes here */}
					</Routes>
				</Box>
			</Box>

			<Box component="footer" sx={{ p: 0, bgcolor: "background.paper" }}>
				<Footer />
			</Box>
		</Box>
	);
};

export default Index;

export const DefaultDashboardContent = () => {
	return (
		<div style={{ marginTop: "50px", marginLeft: "40px" }}>Your dashboard</div>
	);
};
