import { useState, useEffect, useReducer } from "react";
import "./assets/css/tailwind.css";
import "./assets/scss/tailwind.scss";
import "./assets/scss/icons.scss";
import { Route, Routes } from "react-router-dom";
import Index from "./pages/index.js";
// import Login from "./pages/auth/login";
import Loader from "./component/Loader";
import UserContext from "contexts/UserContext";
import DashboardPage from "pages/dashboard/index.js";
import userReducer, {
	initialState as userInitialState,
} from "reducers/userReducer";
import LoginContainer from "pages/auth/LoginContainer";
import RaiseDemandWork from "pages/RaiseDemandWork";
import PrivateRoute from "component/PrivateRoute";
import { RoleProvider } from "contexts/RoleContext";
import PublicRaiseDemandWork from "pages/PublicRaiseDemandWork";
import UpdatePasswordPage from "pages/auth/UpdatePasswordPage";

const App = () => {
	const [loading, setLoading] = useState(false);
	const [userState, userDispatch] = useReducer(userReducer, userInitialState);
	const userContext = { userState, userDispatch };

	useEffect(() => {
		document.documentElement.setAttribute("dir", "ltr");
		handleRouteChange();
	}, []);

	// Route change method
	const handleRouteChange = () => {
		setLoading(false);
	};

	return (
		<UserContext.Provider value={userContext}>
			<RoleProvider>
				{loading && <Loader />}
				<Routes>
					<Route path="/" element={<Index />} />
					<Route path="/index" element={<Index />} />
					<Route path="/auth-login" element={<LoginContainer />} />
					<Route
						path="/raise-demand-work-public"
						element={<PublicRaiseDemandWork />}
					/>

					<Route element={<PrivateRoute />}>
						<Route path="/core/*" element={<DashboardPage />} />
						<Route
							path="/auth-reset-password"
							element={<UpdatePasswordPage />}
						/>
						<Route
							path="/core/raise-demand-work"
							element={<RaiseDemandWork />}
						/>
					</Route>
				</Routes>
			</RoleProvider>
		</UserContext.Provider>
	);
};

export default App;
