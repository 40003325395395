import { Alert, Button } from "@mui/material";
import { FC, useRef, useState } from "react";
import ResendOTP from "../ResendOTP";
import theme from "./theme.module.scss";
import { v4 as uuidv4 } from "uuid";
import { getUser } from "utils/auth";
import { verifyOtp } from "../api";
import CustomOtpInput from "./CustomOtpInput";
import { getCurrentLanguage, translations } from "utils/translations";
import UpdatePassword from "component/UpdatePassword";

type User = {
	id: string;
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber: string;
	sessionToken: string;
};

type LoginMutationResponse = {
	loginUser: User;
};

type LoginMutationVariables = {
	phoneNumber: string;
	otp: string;
};

const EnterOTPExisting: FC<{
	phoneNumber: string;
	onSuccess: (data) => void;
	autofilledOtp?: string;
	newUserFlag?: string;
}> = ({ phoneNumber, onSuccess, autofilledOtp, newUserFlag = "false" }) => {
	const currentLanguage = getCurrentLanguage();
	const t = translations[currentLanguage];
	const [otp, setOtp] = useState(autofilledOtp || "");
	const [validationError, setValidationError] = useState("");
	const [infoMessage, setInfoMessage] = useState(t.otp_sent_message);
	const otpInputRef = useRef<any>(null);
	const [showUpdatePassword, setShowUpdatePassword] = useState(false);
	const [updatePasswordData, setUpdatePasswordData] = useState({
		adminId: "",
		roleId: "",
		budgetYear: "",
	});

	async function onSubmit() {
		setValidationError("");

		const numbers = /^\d{6}$/;

		if (!new RegExp(numbers).test(otp)) {
			setValidationError("Please enter a valid OTP. 6 digits long.");
			return;
		}

		try {
			const result = await verifyOtp(phoneNumber, otp, newUserFlag);
			if (result && result.status) {
				if (result.data?.update_password === "YES") {
					setUpdatePasswordData({
						adminId: result.data.admin_id,
						roleId: result.data.role_id,
						budgetYear: result.data.budget_year,
					});
					setShowUpdatePassword(true);
				} else {
					onSuccess(result);
				}
			} else {
				setValidationError(
					result.message || "OTP verification failed. Please try again."
				);
			}
		} catch (error) {
			console.error("OTP verification error:", error);
			setValidationError("An error occurred. Please try again.");
		}
	}

	const renderInput = (props: any) => (
		<input {...props} className={theme.otpInput} />
	);

	return (
		<div className={`${theme.container} max-w-md mx-auto`}>
			{showUpdatePassword ? (
				<UpdatePassword
					message="Please change your password for security reasons"
					adminId={updatePasswordData.adminId}
					roleId={updatePasswordData.roleId}
					budgetYear={updatePasswordData.budgetYear}
				/>
			) : (
				<>
					<p className={theme.description}>{infoMessage}</p>
					<div></div>
					<CustomOtpInput
						value={otp}
						onChange={(otp) => setOtp(otp)}
						numInputs={6}
						renderInput={renderInput}
						shouldAutoFocus
						ref={otpInputRef}
					/>

					{validationError ? (
						<Alert severity="error" className={theme.errorAlert}>
							{validationError
								? validationError
								: "The OTP you've entered is incorrect. Please try again."}
						</Alert>
					) : null}

					<div className="mt-2">
						<ResendOTP
							phoneNumber={phoneNumber}
							newUserFlag={newUserFlag}
							otpInputRef={otpInputRef}
							resetError={() => {
								setValidationError("");
								setInfoMessage(t.otp_sent_again_message);
							}}
						/>
					</div>
					<Button type="submit" variant="contained" onClick={onSubmit}>
						{t.submit}
					</Button>
				</>
			)}
		</div>
	);
};

export default EnterOTPExisting;
