import React, { useState, useEffect } from "react";
import {
	TextField,
	Button,
	MenuItem,
	Typography,
	Grid,
	Autocomplete,
	Snackbar,
	Alert,
} from "@mui/material";
import classNames from "classnames";
import {
	DOMAIN_URL,
	AUTHORIZATION,
	AUTHENTICATION_KEY,
	APP_PLATFORM,
	APP_APPID,
	CONTENT_TYPE,
} from "utils/config";
import theme from "./theme.module.scss";
import { register } from "../api";

interface State {
	state_id: string;
	state_name: string;
	state_code: string;
	state_num_code: string;
	status_name: string;
	status_id: string;
}

interface ZillaPanchayat {
	zp_id: string;
	zp_code: string;
	zp_name: string;
	state_name: string;
	status_name: string;
	status_id: string;
}

interface TalukPanchayat {
	tp_id: string;
	tp_code: string;
	tp_name: string;
	zp_name: string;
	state_name: string;
	status_name: string;
	status_id: string;
}

interface GramPanchayat {
	gp_id: string;
	gp_code: string;
	gp_name: string;
	tp_name: string;
	zp_name: string;
	state_name: string;
	status_id: string;
}

interface AdminRole {
	admin_role_code: string;
	admin_role_id: string;
	admin_role_name: string;
	admin_type_id: string;
	admin_type_name: string;
	status_id: string;
}

const Register = ({ phoneNumber, password, onSuccess }) => {
	const [hideSubmitButton, toggleHideSubmitButton] = useState(false);
	const [formData, setFormData] = useState({
		firstName: "",
		lastName: "",
		email: "",
		state: "",
		zillaPanchayat: "",
		talukPanchayat: "",
		gramPanchayat: "",
		designation: "",
		adminRole: "",
	});

	const [states, setStates] = useState<State[]>([]);
	const [zillaPanchayats, setZillaPanchayats] = useState<ZillaPanchayat[]>([]);
	const [talukPanchayats, setTalukPanchayats] = useState<TalukPanchayat[]>([]);
	const [gramPanchayats, setGramPanchayats] = useState<GramPanchayat[]>([]);
	const [adminRoles, setAdminRoles] = useState<AdminRole[]>([]);
	const [openSnackbar, setOpenSnackbar] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");

	useEffect(() => {
		// Fetch states
		fetchDataAsync(`${DOMAIN_URL}/state-list`, setStates);
	}, []);

	useEffect(() => {
		// Fetch Zilla Panchayats when state changes
		if (formData.state) {
			fetchDataAsync(
				`${DOMAIN_URL}/zp-list/${formData.state}`,
				setZillaPanchayats
			);
		}
	}, [formData.state]);

	useEffect(() => {
		// Fetch Taluk Panchayats when Zilla Panchayat changes
		if (formData.zillaPanchayat) {
			fetchDataAsync(
				`${DOMAIN_URL}/tp-list/${formData.zillaPanchayat}`,
				setTalukPanchayats
			);
		}
	}, [formData.zillaPanchayat]);

	useEffect(() => {
		// Fetch Gram Panchayats when Taluk Panchayat changes
		if (formData.talukPanchayat) {
			fetchDataAsync(
				`${DOMAIN_URL}/gp-list/${formData.talukPanchayat}`,
				setGramPanchayats
			);
		}
	}, [formData.talukPanchayat]);

	useEffect(() => {
		let panchayatType = "STATE";
		if (formData.gramPanchayat) panchayatType = "GP";
		else if (formData.talukPanchayat) panchayatType = "TP";
		else if (formData.zillaPanchayat) panchayatType = "ZP";

		fetchDataAsync(
			`${DOMAIN_URL}/api/admin/roles_type/${panchayatType}`,
			setAdminRoles
		);
	}, [
		formData.gramPanchayat,
		formData.talukPanchayat,
		formData.zillaPanchayat,
	]);

	const fetchDataAsync = async (url: string, setter: (data: any) => void) => {
		try {
			const response = await fetch(url, {
				method: "GET",
				headers: {
					Authorization: AUTHORIZATION || "",
					Authenticationkey: AUTHENTICATION_KEY || "",
					Platform: APP_PLATFORM || "",
					Appid: APP_APPID || "",
					"Content-Type": CONTENT_TYPE || "",
				},
			});

			if (!response.ok) {
				throw new Error("Network response was not ok");
			}

			const data = await response.json();
			setter(data.results);
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		let updatedFormData = { ...formData, [name]: value };

		if (name === "state") {
			updatedFormData = {
				...updatedFormData,
				zillaPanchayat: "",
				talukPanchayat: "",
				gramPanchayat: "",
				adminRole: "",
			};
		} else if (name === "zillaPanchayat") {
			updatedFormData = {
				...updatedFormData,
				talukPanchayat: "",
				gramPanchayat: "",
				adminRole: "",
			};
		} else if (name === "talukPanchayat") {
			updatedFormData = {
				...updatedFormData,
				gramPanchayat: "",
				adminRole: "",
			};
		}

		setFormData(updatedFormData);
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		toggleHideSubmitButton(true);
		try {
			const registrationData = {
				first_name: formData.firstName,
				middle_name: "",
				last_name: formData.lastName,
				email_address: formData.email,
				phone_number: phoneNumber, // Assuming phoneNumber is available from props
				user_name: phoneNumber, // Using email as username
				admin_password: password, // Assuming password is not set during registration
				state_id: formData.state,
				zp_id: formData.zillaPanchayat,
				tp_id: formData.talukPanchayat,
				gp_id: formData.gramPanchayat,
				role_id: formData.adminRole,
				designation_name: formData.designation,
			};
			const response = await register(registrationData);
			if (response.success) {
				setSnackbarMessage(
					response.message + " Please check back later to sign in."
				);
				setOpenSnackbar(true);
				setTimeout(() => {
					onSuccess();
				}, 3000); // Wait for 3 seconds before calling onSuccess
			} else {
				setSnackbarMessage(
					response.message || "Registration failed. Please try again."
				);
				setOpenSnackbar(true);
			}
		} catch (error) {
			console.error("Error during registration:", error);
			setSnackbarMessage("An unexpected error occurred. Please try again.");
			setOpenSnackbar(true);
		} finally {
			toggleHideSubmitButton(false);
		}
	};

	const handleCloseSnackbar = (
		event: React.SyntheticEvent | Event,
		reason?: string
	) => {
		if (reason === "clickaway") {
			return;
		}
		setOpenSnackbar(false);
	};

	return (
		<div className={classNames(theme.container, "container mx-auto p-4")}>
			<Typography variant="h5" component="p" className={theme.subTitle}>
				Create an account
			</Typography>
			<Typography
				variant="body1"
				component="p"
				className={classNames(theme.description, "mt-2")}
			>
				Welcome to MGNREGA - Gov of KA. Please fill in the information below.
			</Typography>

			<form onSubmit={onSubmit} className="mt-2">
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6}>
						<TextField
							name="firstName"
							label="First Name"
							fullWidth
							margin="normal"
							value={formData.firstName}
							onChange={handleChange}
							required
							inputProps={{ maxLength: 100 }}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							name="lastName"
							label="Last Name"
							fullWidth
							margin="normal"
							value={formData.lastName}
							onChange={handleChange}
							required
							inputProps={{ maxLength: 100 }}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							name="email"
							label="Email"
							type="email"
							fullWidth
							margin="normal"
							value={formData.email}
							onChange={handleChange}
							required
							inputProps={{ maxLength: 100 }}
						/>
					</Grid>
					<Grid item xs={12} md={12}>
						<Grid container spacing={2}>
							<Grid item xs={12} md={3}>
								<TextField
									name="state"
									label="Choose State"
									select
									fullWidth
									margin="normal"
									value={formData.state}
									onChange={handleChange}
								>
									{states.map((state) => (
										<MenuItem key={state.state_id} value={state.state_id}>
											{state.state_name}
										</MenuItem>
									))}
								</TextField>
							</Grid>

							<Grid item xs={12} md={3}>
								<Autocomplete
									id="zillaPanchayat"
									options={zillaPanchayats}
									getOptionLabel={(option) => option.zp_name}
									value={
										zillaPanchayats.find(
											(zp) => zp.zp_id === formData.zillaPanchayat
										) || null
									}
									onChange={(event, newValue) => {
										handleChange({
											target: {
												name: "zillaPanchayat",
												value: newValue ? newValue.zp_id : "",
											},
										});
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											name="zillaPanchayat"
											label="Choose Zilla Panchayat"
											fullWidth
											margin="normal"
											disabled={!formData.state}
										/>
									)}
								/>
							</Grid>

							<Grid item xs={12} md={3}>
								<Autocomplete
									id="talukPanchayat"
									options={talukPanchayats}
									getOptionLabel={(option) => option.tp_name}
									value={
										talukPanchayats.find(
											(tp) => tp.tp_id === formData.talukPanchayat
										) || null
									}
									onChange={(event, newValue) => {
										handleChange({
											target: {
												name: "talukPanchayat",
												value: newValue ? newValue.tp_id : "",
											},
										});
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											name="talukPanchayat"
											label="Choose Taluk Panchayat"
											fullWidth
											margin="normal"
											disabled={!formData.zillaPanchayat}
										/>
									)}
									disabled={!formData.zillaPanchayat}
								/>
							</Grid>

							<Grid item xs={12} md={3}>
								<Autocomplete
									id="gramPanchayat"
									options={gramPanchayats}
									getOptionLabel={(option) => option.gp_name}
									value={
										gramPanchayats.find(
											(gp) => gp.gp_id === formData.gramPanchayat
										) || null
									}
									onChange={(event, newValue) => {
										handleChange({
											target: {
												name: "gramPanchayat",
												value: newValue ? newValue.gp_id : "",
											},
										});
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											name="gramPanchayat"
											label="Choose Gram Panchayat"
											fullWidth
											margin="normal"
											disabled={!formData.talukPanchayat}
										/>
									)}
									disabled={!formData.talukPanchayat}
									disableClearable={!formData.talukPanchayat}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} md={6}>
						<Autocomplete
							id="adminRole"
							options={adminRoles}
							getOptionLabel={(option) => option.admin_role_name}
							value={
								adminRoles.find(
									(role) => role.admin_role_id === formData.adminRole
								) || null
							}
							onChange={(event, newValue) => {
								handleChange({
									target: {
										name: "adminRole",
										value: newValue ? newValue.admin_role_id : "",
									},
								});
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									name="adminRole"
									label="Choose Admin Role"
									fullWidth
									margin="normal"
								/>
							)}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<TextField
							name="designation"
							label="Designation"
							fullWidth
							margin="normal"
							value={formData.designation}
							onChange={handleChange}
							required
						/>
					</Grid>
				</Grid>

				<div className={classNames(theme.submitButtonContainer, "mt-4")}>
					<Button
						type="submit"
						variant="contained"
						color="primary"
						disabled={hideSubmitButton}
						fullWidth
					>
						Register
					</Button>
				</div>
			</form>
			<Snackbar
				open={openSnackbar}
				autoHideDuration={6000}
				onClose={handleCloseSnackbar}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				sx={{ marginTop: "80px" }}
			>
				<Alert
					onClose={handleCloseSnackbar}
					severity="success"
					sx={{ width: "100%" }}
				>
					{snackbarMessage}
				</Alert>
			</Snackbar>
		</div>
	);
};

export default Register;
