import React, { useState } from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Button,
	IconButton,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import FileUploadButton from "component/FileUploadbutton";
import { deleteActionPlan } from "../api";

const ActionPlanProceeding = ({
	data,
	onDelete,
	onUploadComplete,
	showActions,
}) => {
	const [openDialog, setOpenDialog] = useState(false);
	const [deleteId, setDeleteId] = useState(null);

	const generateFileUrl = (imgPath, apPdfId) => {
		return `${imgPath}${apPdfId}`;
	};

	const handleDeleteClick = (apPdfId) => {
		setDeleteId(apPdfId);
		setOpenDialog(true);
	};

	const handleDeleteConfirm = async () => {
		try {
			const response = await deleteActionPlan(deleteId);
			if (response.status) {
				onDelete(deleteId);
			} else {
				console.error("Delete failed:", response.message);
				// You might want to show an error message to the user here
			}
		} catch (error) {
			console.error("Error deleting action plan:", error);
			// You might want to show an error message to the user here
		}
		setOpenDialog(false);
	};

	const handleDeleteCancel = () => {
		setOpenDialog(false);
		setDeleteId(null);
	};

	return (
		<>
			<TableContainer component={Paper}>
				<Table stickyHeader aria-label="action plan proceeding table">
					<TableHead>
						<TableRow>
							{Object.keys(data.results[0]).map((key) => (
								<TableCell key={key}>
									{key.replace("_", " ").toUpperCase()}
								</TableCell>
							))}
							<TableCell>Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{data.results.map((row, index) => (
							<TableRow key={`action-plan-${index}`}>
								{Object.entries(row).map(([key, value]) => (
									<TableCell key={`${row.ap_pdf_id}-${key}`}>
										{key === "file_name" ? (
											<div className="flex space-x-2">
												<Button
													variant="contained"
													color="primary"
													href={generateFileUrl(data.img_path, row.file_name)}
													target="_blank"
													rel="noopener noreferrer"
												>
													View
												</Button>
												<Button
													variant="contained"
													color="secondary"
													href={generateFileUrl(data.img_path, row.file_name)}
													download
												>
													Download
												</Button>
											</div>
										) : (
											value
										)}
									</TableCell>
								))}
								<TableCell>
									{/* {showActions(row) && ( */}
									<div className="flex space-x-2">
										<IconButton
											onClick={() => handleDeleteClick(row.ap_pdf_id)}
											color="error"
										>
											<DeleteIcon />
										</IconButton>
										<FileUploadButton
											onUploadComplete={onUploadComplete}
											label="Upload"
											apPdfId={row.ap_pdf_id}
										/>
									</div>
									{/* )} */}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>

			<Dialog
				open={openDialog}
				onClose={handleDeleteCancel}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Are you sure you want to delete this action plan?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleDeleteCancel}>Cancel</Button>
					<Button onClick={handleDeleteConfirm} autoFocus>
						Delete
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default ActionPlanProceeding;
