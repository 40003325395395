import { createContext } from "react";

interface userContextState {
	isAuthenticated: boolean;
	deliveryPincode: string;
	showRegistrationDialog: boolean;
}

interface UserContextData {
	userState: userContextState;
	userDispatch: ({
		type,
		payload,
	}: {
		type:
			| "USER_AUTHENTICATED"
			| "USER_UNAUTHENTICATED"
			| "USER_REGISTRATION_DIALOG_CLOSE"
			| "FORCE_RELOAD";
		payload: any;
	}) => void;
}

const UserContext = createContext({} as UserContextData);

export default UserContext;
