import React, { forwardRef, useImperativeHandle, useRef } from "react";
import OtpInput from "react-otp-input";
import styles from "pages/auth/EnterOTPExisting/theme.module.scss";

interface CustomOtpInputProps {
  value: string;
  onChange: (otp: string) => void;
  numInputs: number;
  renderInput: (props: any) => JSX.Element;
  shouldAutoFocus?: boolean;
}

const CustomOtpInput = forwardRef<any, CustomOtpInputProps>((props, ref) => {
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const { value, onChange, numInputs } = props;

  useImperativeHandle(ref, () => ({
    clear() {
      onChange(""); // Clear the OTP value in the parent component
    },
    focusInput(index: number) {
      if (inputRefs.current[index]) {
        inputRefs.current[index]?.focus();
      }
    },
  }));

  const handleChange = (otp: string) => {
    const sanitizedOtp = otp.replace(/[^0-9]/g, '').slice(0, numInputs);
    onChange(sanitizedOtp);
    if (sanitizedOtp.length < numInputs) {
      inputRefs.current[sanitizedOtp.length]?.focus();
    }
  };

  const renderInputWithRef = (inputProps: any, index: number) => {
    return (
      <input
        {...inputProps}
        className={`${styles.otpInput} ${inputProps.value ? styles.filled : ''}`}
        ref={(el) => (inputRefs.current[index] = el)}
        key={index}
        onFocus={(e) => e.target.classList.add(styles.otpInputOnFocus)}
        onBlur={(e) => e.target.classList.remove(styles.otpInputOnFocus)}
        maxLength={1}
        onChange={(e) => {
          const newValue = e.target.value;
          if (newValue.length <= 1) {
            // Single character input
            const newOtp = value.slice(0, index) + newValue + value.slice(index + 1);
            handleChange(newOtp);
          } else {
            // Pasted input
            handleChange(newValue);
          }
        }}
        onKeyDown={(e) => {
          if (e.key === 'Backspace' && !inputProps.value) {
            e.preventDefault();
            const newOtp = value.slice(0, index - 1) + value.slice(index);
            handleChange(newOtp);
            inputRefs.current[index - 1]?.focus();
          }
        }}
        onPaste={(e) => {
          e.preventDefault();
          const pastedData = e.clipboardData.getData('text/plain');
          handleChange(pastedData);
        }}
      />
    );
  };

  return (
    <OtpInput
      value={value}
      onChange={handleChange}
      numInputs={numInputs}
      renderInput={renderInputWithRef}
      shouldAutoFocus={props.shouldAutoFocus}
      inputType="tel"
      containerStyle="display: flex; justify-content: space-between;"
    />
  );
});

export default CustomOtpInput;
