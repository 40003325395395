import React, { useState, useEffect } from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { fetchBudgetYears } from 'pages/dashboard/api';
import Cookies from 'js-cookie';

const YearSelect = () => {
  const [years, setYears] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedYear, setSelectedYear] = useState(Cookies.get('budget_year') || '');

  useEffect(() => {
    const loadYears = async () => {
      try {
        const fetchedYears = await fetchBudgetYears();
        setYears(fetchedYears);
        setLoading(false);
        
        // If no year is selected and we have years, select the first one
        if (!selectedYear && fetchedYears.length > 0) {
          const defaultYear = fetchedYears[0].budget_year_id;
          setSelectedYear(defaultYear);
          Cookies.set('budget_year', defaultYear);
        }
      } catch (error) {
        console.error('Error fetching budget years:', error);
        setLoading(false);
      }
    };

    loadYears();
  }, [selectedYear]);

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedYear(value);
    Cookies.set('budget_year', value);
    window.location.reload();
  };

  return (
    <FormControl className="w-full sm:w-48">
      <InputLabel 
        id="year-select-label" 
        className="text-sm text-gray-400 dark:text-white/70 w-full xs:w-auto"
        sx={{ 
          lineHeight: '1rem', 
          transform: 'translate(14px, 9px) scale(1)',
          '&.Mui-focused, &.MuiInputLabel-shrink': {
            transform: 'translate(14px, -9px) scale(0.75)',
          },
          width: '100%',
          '@media (min-width: 480px)': {
            width: 'auto',
          },
        }}
      >
        Year
      </InputLabel>
      <Select
        labelId="year-select-label"
        id="year-select"
        value={selectedYear}
        label="Year"
        onChange={handleChange}
        className="h-9 text-sm bg-transparent text-gray-400 dark:text-white/70 border-gray-400 dark:border-white/70"
        sx={{ 
          '& .MuiSelect-select': { 
            paddingTop: '8px', 
            paddingBottom: '8px' 
          },
          '& .MuiOutlinedInput-notchedOutline': {
            top: '-5px',
            borderColor: 'currentColor',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'currentColor',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'currentColor',
          },
          '& .MuiSvgIcon-root': {
            color: 'currentColor',
          },
        }}
        MenuProps={{
          PaperProps: {
            style: { maxHeight: 200 },
            className: 'bg-white dark:bg-slate-900 text-gray-900 dark:text-white',
          },
        }}
      >
        <MenuItem value="" disabled>
          Select Year
        </MenuItem>
        {years.map((yearObj) => (
          <MenuItem key={yearObj.budget_year_id} value={yearObj.budget_year_id} className="text-sm">
            {yearObj.budget_year}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default YearSelect;