import Cookies from "js-cookie";

export const DOMAIN_URL = process.env.REACT_APP_DOMAIN_URL;
export const AUTHORIZATION = process.env.REACT_APP_AUTHORIZATION;
export const AUTHENTICATION_KEY = process.env.REACT_APP_AUTHENTICATION_KEY;
export const APP_PLATFORM = process.env.REACT_APP_PLATFORM;
export const APP_APPID = process.env.REACT_APP_APPID;
export const CONTENT_TYPE = process.env.REACT_APP_CONTENT_TYPE;

export const ADMIN_ID = Cookies.get("admin_id");
export const ROLE_ID = Cookies.get("role_id");
export const BUDGET_YEAR = Cookies.get("budget_year");
export const getAuthHeaders = (
	includeContentType = true,
	adminId?: string,
	roleId?: string,
	budgetYear?: string
) => ({
	Authorization: AUTHORIZATION || "",
	Authenticationkey: AUTHENTICATION_KEY || "",
	Adminid: adminId || ADMIN_ID || "",
	Roleid: roleId || ROLE_ID,
	Budgetyear: budgetYear || BUDGET_YEAR,
	Platform: APP_PLATFORM || "",
	Appid: APP_APPID || "",
	Languagecode: localStorage.getItem("language") || "en",
	...(includeContentType
		? { "Content-Type": CONTENT_TYPE || "multipart/form-data" }
		: {}),
});
