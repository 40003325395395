import React from "react";
import { changeLanguage, getCurrentLanguage } from "utils/translations";
import { Select, MenuItem } from "@mui/material";
import { SvgIcon } from "@mui/material";

const CustomArrowIcon = (props) => (
	<SvgIcon {...props}>
		<path d="M7 10l5 5 5-5z" />
	</SvgIcon>
);

const LanguageSwitcher = () => {
	const currentLanguage = getCurrentLanguage();

	const handleLanguageChange = (event) => {
		changeLanguage(event.target.value);
	};

	return (
		<Select
			value={currentLanguage}
			onChange={handleLanguageChange}
			IconComponent={CustomArrowIcon}
			sx={{
				backgroundColor: "transparent",
				color: "inherit",
				"& .MuiSelect-select": {
					padding: "0.5rem",
					fontSize: "1rem",
					lineHeight: "1.5rem",
				},
				"& .MuiOutlinedInput-notchedOutline": {
					border: "none",
				},
				"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
					border: "none",
				},
				"& .MuiSvgIcon-root": {
					color: "inherit",
				},
			}}
		>
			<MenuItem value="en">English</MenuItem>
			<MenuItem value="ka">ಕನ್ನಡ</MenuItem>
		</Select>
	);
};

export default LanguageSwitcher;
