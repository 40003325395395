import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TableSortLabel,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";

const StyledTableContainer = styled(TableContainer)({
  maxWidth: "100%",
  overflowX: "auto",
});

const DemandHistoryTable = ({ data, title }) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [search, setSearch] = useState("");

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  return (
    <Paper sx={{ width: "100%", mb: 2 }}>
      <div className="flex flex-col sm:flex-row items-baseline justify-between px-4 pb-4 pt-[6px] sm:p-4">
        <Typography
          variant="h6"
          component="div"
          className="text-2xl font-bold ml-10 xs:ml-40 sm:ml-0 xs:pt-2 pt-1"
        >
          {title}
        </Typography>
        <div className="flex flex-col sm:flex-row items-center">
          {data?.results && data.results.length > 0 && (
            <TextField
              variant="outlined"
              placeholder="Search…"
              value={search}
              onChange={handleSearch}
              className="w-full sm:w-64"
            />
          )}
        </div>
      </div>

      {!data || !data.results || data.results.length === 0 ? (
        <div className="p-4">No data available</div>
      ) : (
        <StyledTableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {data.results[0] &&
                  Object.keys(data.results[0])
                    .filter((key) => !key.includes("_id"))
                    .map((key) => (
                      <TableCell
                        key={key}
                        sortDirection={orderBy === key ? order : false}
                        className="bg-slate-100 text-dark-600 font-semibold align-top"
                      >
                        <TableSortLabel
                          active={orderBy === key}
                          direction={orderBy === key ? order : "asc"}
                          onClick={() => handleRequestSort(key)}
                        >
                          {key.replaceAll("_", " ").toUpperCase()}
                        </TableSortLabel>
                      </TableCell>
                    ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.results
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow hover tabIndex={-1} key={`${data.key}-${index}`}>
                    {Object.keys(row)
                      .filter((key) => !key.includes("_id"))
                      .map((key) => (
                        <TableCell key={`${index}-${key}`}>
                          {row[key]}
                        </TableCell>
                      ))}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
      )}
      {!data?.results || data.results.length === 0 ? null : (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.results.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );
};

export default DemandHistoryTable;
