import React, { useContext, useState } from "react";
import LoginForm from "./LoginForm";
import OTPVerification from "./OTPVerification";
import RegistrationFlow from "./RegistrationFlow";
import ConfirmationDialog from "./ConfirmationDialog";
import { CheckUserResult, UserData } from "./types";
import { generateOtp, login } from "./api";
import UserContext from "contexts/UserContext";
import {
	Button,
	Grid,
	Container,
	Box,
	Typography,
	Snackbar,
	Alert,
	ButtonGroup,
} from "@mui/material";
import { Link } from "react-router-dom";
import Footer from "component/Footer";
import Header from "component/Header/CommonHeader";
import { useNavigate } from "react-router-dom";

const LoginContainer = () => {
	const [phoneNumber, setPhoneNumber] = useState("");
	const [password, setPassword] = useState("");
	const [showOtpInput, setShowOtpInput] = useState<
		"FIRST_TIME" | "EXISTING" | "NONE"
	>("NONE");
	const [showSignUp, setShowSignUp] = useState(false);
	const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
	const [openSnackbar, setOpenSnackbar] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const { userState, userDispatch } = useContext(UserContext);
	const [registrationSuccess, setRegistrationSuccess] = useState(false);
	const [loginError, setLoginError] = useState("");
	const navigate = useNavigate();
	const [activeTab, setActiveTab] = useState<"signin" | "signup">("signin");
	const [autofilledOtp, setAutofilledOtp] = useState("");

	function handleUserFound(result: CheckUserResult) {
		if (result.status) {
			setLoginError("");
			if (result.data?.user_name) {
				setPhoneNumber(result.data.user_name);
			}
			if (result.data?.type === "OTP") {
				setShowOtpInput("EXISTING");
				// setAutofilledOtp(result.data.otp_value);
			} else if (result.data?.admin_details) {
				onLoginSuccess(result);
			} else {
				setLoginError("Unexpected response format. Please try again.");
			}
		} else if (result.new_user) {
			// Handle new user case
			setPhoneNumber(result.data?.user_name || "");
			handleSignUp();
		} else {
			setSnackbarMessage(
				"Invalid username or password. Please check and try again."
			);
			setOpenSnackbar(true);
		}
	}

	function handleUserNotFound(phoneNumber: string, password: string) {
		setLoginError("");
		setPhoneNumber(phoneNumber);
		setPassword(password);
		if (activeTab === "signup") {
			handleSignUp(phoneNumber); // Pass phoneNumber directly
		} else {
			setOpenConfirmationDialog(true);
		}
	}

	function handleSignUp(phoneNumberParam?: string) {
		const numberToUse = phoneNumberParam || phoneNumber;
		if (!numberToUse) {
			setSnackbarMessage("Phone number is required for sign up.");
			setOpenSnackbar(true);
			return;
		}
		generateOtp(numberToUse, "true").then((otpGenerated) => {
			if (otpGenerated) {
				setShowOtpInput("FIRST_TIME");
			} else {
				setSnackbarMessage("Failed to generate OTP. Please try again.");
				setOpenSnackbar(true);
			}
		});
	}

	async function handleConfirmationContinue() {
		setOpenConfirmationDialog(false);
		const otpGenerated = await generateOtp(phoneNumber, "true");
		// const otpGenerated = "898998"; // api works the remove this and uncomment above line

		if (otpGenerated) {
			setShowOtpInput("FIRST_TIME");
		} else {
			// Handle OTP generation failure
			setSnackbarMessage("Failed to generate OTP. Please try again.");
			setOpenSnackbar(true);
		}
	}

	function handleOTPSuccess(data: CheckUserResult) {
		onLoginSuccess(data);
	}

	function onLoginSuccess(data: CheckUserResult) {
		// Handle successful login
		login(data);
		userDispatch({
			type: "USER_AUTHENTICATED",
			payload: {
				isAuthenticated: true,
				showRegistrationDialog: false,
			},
		});
		userDispatch({ type: "FORCE_RELOAD", payload: {} });
		// Add a small delay before navigation to ensure context is updated

		setTimeout(() => {
			// Force a context update to trigger re-renders
			navigate("/core/dashboard");
			window.location.reload();
		}, 100);
	}

	function handleRegistrationSuccess() {
		setShowSignUp(false);
		setRegistrationSuccess(true);
	}

	function handleBackToHome() {
		setRegistrationSuccess(false);
		setShowOtpInput("NONE");
		navigate("/");
	}

	return (
		<Box className="flex flex-col min-h-screen">
			<Header />
			<Container className="flex-grow" maxWidth="lg">
				<Grid container justifyContent="center">
					<Grid item xs={12}>
						<Box className="flex flex-col md:px-12 py-12 px-3">
							<Box className="my-auto">
								<Box className="mt-1 w-full max-w-sm md:max-w-full m-auto px-6 py-8 bg-white dark:bg-slate-900 rounded-md shadow-lg shadow-slate-500 dark:shadow-slate-800">
									<Box display="flex" justifyContent="center" className="mb-8">
										{/* <ButtonGroup variant="text" fullWidth className="mb-8"> */}
										<Button
											variant="text"
											disabled
											className={`
												text-orange-500 border-b-4 border-orange-500
												justify-center
											`}
										>
											Sign In
										</Button>
										{/* <Button
											onClick={() => setActiveTab("signup")}
											className={`${
												activeTab === "signup"
													? "text-orange-500 border-b-4 border-orange-500"
													: "text-gray-500"
											} justify-start`}
										>
											Sign Up
										</Button> */}
										{/* </ButtonGroup> */}
									</Box>
									<Grid item xs={12} className="mx-auto">
										{showOtpInput === "NONE" && !registrationSuccess && (
											<Grid container spacing={2}>
												<Grid item xs={12} md={6} className="mx-auto">
													<Box display="flex" justifyContent="center">
														<LoginForm
															onUserFound={handleUserFound}
															onUserNotFound={handleUserNotFound}
															activeTab={activeTab}
														/>
													</Box>
													{loginError && (
														<Typography
															color="error"
															className="mt-2 text-center"
														>
															{loginError}
														</Typography>
													)}
													{/* <Typography
														variant="body2"
														color="primary"
														className="mt-2 text-center"
													>
														<Link
															to="/auth-reset-password"
															style={{
																textDecoration: "none",
																color: "inherit",
															}}
														>
															Forgot Password?
														</Link>
													</Typography> */}
												</Grid>
												{showOtpInput !== "NONE" &&
													!showSignUp &&
													!registrationSuccess && (
														<Grid item xs={12} md={4} className="mx-auto">
															<Box maxWidth="sm" className="w-full">
																<OTPVerification
																	phoneNumber={phoneNumber}
																	onOTPSuccess={handleOTPSuccess}
																	onFirstTimeOTPSuccess={() => {
																		setShowSignUp(true);
																	}}
																	type={showOtpInput}
																	onBack={() => setShowOtpInput("NONE")}
																/>
															</Box>
														</Grid>
													)}
												{showSignUp && (
													<Grid item xs={12}>
														<RegistrationFlow
															phoneNumber={phoneNumber}
															password={password}
															onSuccess={handleRegistrationSuccess}
														/>
													</Grid>
												)}
											</Grid>
										)}
										{showOtpInput !== "NONE" &&
											!showSignUp &&
											!registrationSuccess && (
												<OTPVerification
													phoneNumber={phoneNumber}
													onOTPSuccess={handleOTPSuccess}
													onFirstTimeOTPSuccess={() => setShowSignUp(true)}
													type={showOtpInput}
													onBack={() => setShowOtpInput("NONE")}
													// autofilledOtp={autofilledOtp}
												/>
											)}
										{showSignUp && (
											<RegistrationFlow
												phoneNumber={phoneNumber}
												password={password}
												onSuccess={handleRegistrationSuccess}
											/>
										)}
										{registrationSuccess && (
											<Box textAlign="center">
												<Typography variant="h6" className="mb-4">
													Registration Successful!
												</Typography>
												<Button
													variant="contained"
													color="primary"
													onClick={handleBackToHome}
												>
													Back to Home
												</Button>
											</Box>
										)}
									</Grid>

									{/* Snackbar moved inside the inner Box */}
									<Snackbar
										open={openSnackbar}
										autoHideDuration={6000}
										onClose={() => setOpenSnackbar(false)}
										anchorOrigin={{ vertical: "top", horizontal: "center" }}
										sx={{ marginTop: "80px" }}
									>
										<Alert
											onClose={() => setOpenSnackbar(false)}
											severity="info"
											sx={{ width: "100%" }}
										>
											{snackbarMessage}
										</Alert>
									</Snackbar>
								</Box>
							</Box>
						</Box>
					</Grid>
				</Grid>
			</Container>
			<Footer />

			<ConfirmationDialog
				open={openConfirmationDialog}
				onConfirm={handleConfirmationContinue}
				onCancel={() => setOpenConfirmationDialog(false)}
			/>
		</Box>
	);
};

export default LoginContainer;
