import React, { useState, useEffect } from "react";
import { Typography, Card, CardContent, CircularProgress } from "@mui/material";
import { fetchUserWorkList } from "pages/dashboard/api";
import { translations, getCurrentLanguage } from "../../utils/translations";

const GuestHistory = ({ phoneNumber, onLimitReached }) => {
	const currentLanguage = getCurrentLanguage();
	const t = translations[currentLanguage];

	const [workHistory, setWorkHistory] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [showWarning, setShowWarning] = useState(false);

	useEffect(() => {
		const fetchHistory = async () => {
			try {
				const data = await fetchUserWorkList(phoneNumber);
				setWorkHistory(data.results.slice(0, 3));
				const isLimitReached = data.results.length > 3;
				setShowWarning(isLimitReached);
				onLimitReached(isLimitReached);
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};

		fetchHistory();
	}, [phoneNumber, onLimitReached]);

	if (loading) {
		return <CircularProgress />;
	}

	if (error) {
		return <Typography color="error">{error}</Typography>;
	}

	if (workHistory.length === 0) {
		return null;
	}

	return (
		<div>
			{showWarning && (
				<Typography variant="h6" color="red" sx={{ mb: 2 }}>
					You have reached the limit of 3 allowed demand requests with this
					account.
				</Typography>
			)}
			<Typography variant="h6" gutterBottom>
				{t.recent_work_history}
			</Typography>
			{workHistory.map((item) => (
				<Card key={item.work_demand_id} sx={{ mb: 2 }}>
					<CardContent>
						<Typography variant="subtitle1">{item.work_title}</Typography>
						<Typography variant="body2" color="text.secondary">
							{t.work_type}: {item.work_type_name}
						</Typography>
						<Typography variant="body2" color="text.secondary">
							{t.work_code}: {item.work_code}
						</Typography>
						<Typography variant="body2" color="text.secondary">
							{t.wage}: ₹{item.wage} | {t.material}: ₹{item.material}
						</Typography>
					</CardContent>
				</Card>
			))}
		</div>
	);
};

export default GuestHistory;
