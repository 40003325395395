import React, { useState, useEffect } from "react";
import { Grid, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { fetchSubCategories } from "pages/dashboard/services/getCategories";
import { getCurrentLanguage, translations } from "utils/translations";

const WorkFilters = ({ onFilterChange, initialFilters }) => {
	const currentLanguage = getCurrentLanguage();
	const t = translations[currentLanguage];

	const [categoryList, setCategoryList] = useState([]);
	const [subType1List, setSubType1List] = useState([]);
	const [subType2List, setSubType2List] = useState([]);
	const [subType3List, setSubType3List] = useState([]);
	const [subType4List, setSubType4List] = useState([]);

	const [category, setCategory] = useState(initialFilters.category || "");
	const [subType1, setSubType1] = useState(initialFilters.subType1 || "");
	const [subType2, setSubType2] = useState(initialFilters.subType2 || "");
	const [subType3, setSubType3] = useState(initialFilters.subType3 || "");
	const [subType4, setSubType4] = useState(initialFilters.subType4 || "");

	const fetchNextLevelSubCategories = async (category_id) => {
		try {
			const response = await fetchSubCategories(category_id);
			return response.results;
		} catch (error) {
			console.error("Error fetching subcategories:", error);
			return [];
		}
	};

	useEffect(() => {
		const fetchInitialData = async () => {
			// Fetch top-level categories
			const topLevelResponse = await fetchSubCategories("");
			setCategoryList(topLevelResponse.results);

			if (initialFilters.category) {
				const subType1Response = await fetchNextLevelSubCategories(
					initialFilters.category
				);
				setSubType1List(subType1Response);

				if (initialFilters.subType1) {
					const subType2Response = await fetchNextLevelSubCategories(
						initialFilters.subType1
					);
					setSubType2List(subType2Response);

					if (initialFilters.subType2) {
						const subType3Response = await fetchNextLevelSubCategories(
							initialFilters.subType2
						);
						setSubType3List(subType3Response);

						if (initialFilters.subType3) {
							const subType4Response = await fetchNextLevelSubCategories(
								initialFilters.subType3
							);
							setSubType4List(subType4Response);
						}
					}
				}
			}
		};

		fetchInitialData();
	}, []); // Empty dependency array to run only once on mount

	const handleCategoryChange = async (e) => {
		const selectedCategory = e.target.value;
		setCategory(selectedCategory);
		setSubType1("");
		setSubType2("");
		setSubType3("");
		setSubType4("");

		const subCategories = await fetchNextLevelSubCategories(selectedCategory);
		setSubType1List(subCategories);
		setSubType2List([]);
		setSubType3List([]);
		setSubType4List([]);
	};

	const handleSubType1Change = async (e) => {
		const selectedSubType1 = e.target.value;
		setSubType1(selectedSubType1);
		setSubType2("");
		setSubType3("");
		setSubType4("");

		const subCategories = await fetchNextLevelSubCategories(selectedSubType1);
		setSubType2List(subCategories);
		setSubType3List([]);
		setSubType4List([]);
	};

	const handleSubType2Change = async (e) => {
		const selectedSubType2 = e.target.value;
		setSubType2(selectedSubType2);
		setSubType3("");
		setSubType4("");

		const subCategories = await fetchNextLevelSubCategories(selectedSubType2);
		setSubType3List(subCategories);
		setSubType4List([]);
	};

	const handleSubType3Change = async (e) => {
		const selectedSubType3 = e.target.value;
		setSubType3(selectedSubType3);
		setSubType4("");

		const subCategories = await fetchNextLevelSubCategories(selectedSubType3);
		setSubType4List(subCategories);
	};

	const handleSubType4Change = (e) => {
		setSubType4(e.target.value);
	};

	// Notify parent component about filter changes
	useEffect(() => {
		onFilterChange({
			category,
			subType1,
			subType2,
			subType3,
			subType4,
		});
	}, [category, subType1, subType2, subType3, subType4, onFilterChange]);

	return (
		<Grid container spacing={2} sx={{ mb: 2 }}>
			<Grid item xs={12} sm={6} md={3}>
				<FormControl fullWidth>
					<InputLabel>{t.simplified_work_category}</InputLabel>
					<Select
						value={category}
						label={t.simplified_work_category}
						onChange={handleCategoryChange}
					>
						{categoryList.map((category) => (
							<MenuItem
								key={category.work_scategory_id}
								value={category.work_scategory_id}
							>
								{category.sub_category_name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Grid>

			<Grid item xs={12} sm={6} md={3}>
				<FormControl fullWidth disabled={!subType1List.length}>
					<InputLabel>{t.work_sub_type_1}</InputLabel>
					<Select
						value={subType1}
						label={t.work_sub_type_1}
						onChange={handleSubType1Change}
					>
						{subType1List.map((subType) => (
							<MenuItem
								key={subType.work_scategory_id}
								value={subType.work_scategory_id}
							>
								{subType.sub_category_name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Grid>

			<Grid item xs={12} sm={6} md={3}>
				<FormControl fullWidth disabled={!subType2List.length}>
					<InputLabel>{t.work_sub_type_2}</InputLabel>
					<Select
						value={subType2}
						label={t.work_sub_type_2}
						onChange={handleSubType2Change}
					>
						{subType2List.map((subType) => (
							<MenuItem
								key={subType.work_scategory_id}
								value={subType.work_scategory_id}
							>
								{subType.sub_category_name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Grid>

			<Grid item xs={12} sm={6} md={3}>
				<FormControl fullWidth disabled={!subType3List.length}>
					<InputLabel>{t.work_sub_type_3}</InputLabel>
					<Select
						value={subType3}
						label={t.work_sub_type_3}
						onChange={handleSubType3Change}
					>
						{subType3List.map((subType) => (
							<MenuItem
								key={subType.work_scategory_id}
								value={subType.work_scategory_id}
							>
								{subType.sub_category_name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Grid>

			<Grid item xs={12} sm={6} md={3}>
				<FormControl fullWidth disabled={!subType4List.length}>
					<InputLabel>{t.work_sub_type_4}</InputLabel>
					<Select
						value={subType4}
						label={t.work_sub_type_4}
						onChange={handleSubType4Change}
					>
						{subType4List.map((subType) => (
							<MenuItem
								key={subType.work_scategory_id}
								value={subType.work_scategory_id}
							>
								{subType.sub_category_name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Grid>
		</Grid>
	);
};

export default WorkFilters;
