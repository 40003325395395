import React, { useState, useEffect, useCallback, useContext } from "react";
import {
	TextField,
	Autocomplete,
	Button,
	Typography,
	Card,
	CardContent,
	CardMedia,
	Alert,
	Snackbar,
	Link,
	CircularProgress,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Cookies from "js-cookie";
import { DOMAIN_URL, getAuthHeaders } from "utils/config";
import WorkList from "./WorkList";
import { useNavigate } from "react-router-dom";
import { generateOtp, verifyOtp } from "pages/auth/api";
import RoleContext from "contexts/RoleContext";
import Confirmation from "./RaiseNewDemand/Confirmation";
import GuestHistory from "./RaiseNewDemand/GuestHistory";
import { getCurrentLanguage, translations } from "utils/translations";

const RaiseNewDemand = ({
	isPublic = false,
	backToRaiseNewDemandClicked = () => {},
	requestedNewAccountDemand = () => {},
}) => {
	const { selectedRole } = useContext(RoleContext);
	const navigate = useNavigate();
	const [formData, setFormData] = useState({
		zp_id: "",
		tp_id: "",
		gp_id: "",
		village_id: "",
		work_type_id: "",
		job_card_id: "",
		remarks: "",
	});

	const [zillaPanchayats, setZillaPanchayats] = useState([]);
	const [talukPanchayats, setTalukPanchayats] = useState([]);
	const [gramPanchayats, setGramPanchayats] = useState([]);
	const [villages, setVillages] = useState([]);
	const [workTypes, setWorkTypes] = useState([]);
	const [jobCards, setJobCards] = useState([]);
	const [showWorkList, setShowWorkList] = useState(false);
	const [isAdmin, setIsAdmin] = useState(false);
	const [confirmationData, setConfirmationData] = useState(null);
	const [error, setError] = useState(null);
	const [snackbar, setSnackbar] = useState({
		open: false,
		message: "",
		severity: "info",
	});

	const [currentRole, setCurrentRole] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [isLimitReached, setIsLimitReached] = useState(false);
	const currentLanguage = getCurrentLanguage();
	const t = translations[currentLanguage];

	const handleLimitReached = (limitReached) => {
		setIsLimitReached(limitReached);
	};

	const fetchDataAsync = useCallback(async (url, setter) => {
		try {
			const response = await fetch(url, {
				method: "GET",
				headers: getAuthHeaders(),
			});

			if (!response.ok) {
				throw new Error("Network response was not ok");
			}

			const data = await response.json();
			setter(data.results);
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	}, []);

	const fetchCurrentRole = useCallback(async () => {
		const roleId = Cookies.get("role_id");
		if (!roleId) return null;

		try {
			const response = await fetch(`${DOMAIN_URL}/user-roles`, {
				method: "GET",
				headers: getAuthHeaders(),
			});

			if (!response.ok) throw new Error("Failed to fetch user roles");

			const data = await response.json();
			if (data.status && Array.isArray(data.results)) {
				return data.results.find((role) => role.role_id === roleId) || null;
			}
		} catch (error) {
			console.error("Error fetching current role:", error);
		}
		return null;
	}, []);

	const initializeFormData = useCallback(async () => {
		setIsLoading(true);
		const role = await fetchCurrentRole();
		setCurrentRole(role);

		let newFormData = {};
		if (role && !isPublic) {
			if (role.zp_id) {
				newFormData.zp_id = role.zp_id;
				setZillaPanchayats([{ zp_id: role.zp_id, zp_name: role.zp_name }]);
			}
			if (role.tp_id) {
				newFormData.tp_id = role.tp_id;
				setTalukPanchayats([{ tp_id: role.tp_id, tp_name: role.tp_name }]);
			}
			if (role.gp_id) {
				newFormData.gp_id = role.gp_id;
				setGramPanchayats([{ gp_id: role.gp_id, gp_name: role.gp_name }]);
			}

			if (!role.zp_id && !role.tp_id && !role.gp_id) {
				await fetchDataAsync(`${DOMAIN_URL}/zp-list`, setZillaPanchayats);
			}

			// Fetch next level data if needed
			if (role.zp_id && !role.tp_id) {
				await fetchDataAsync(
					`${DOMAIN_URL}/tp-list/${role.zp_id}`,
					setTalukPanchayats
				);
			} else if (role.tp_id && !role.gp_id) {
				await fetchDataAsync(
					`${DOMAIN_URL}/gp-list/${role.tp_id}`,
					setGramPanchayats
				);
			}

			if (role.gp_id) {
				await fetchDataAsync(
					`${DOMAIN_URL}/village-list/${role.gp_id}`,
					setVillages
				);
			}
		} else {
			// If no role, fetch initial ZP list
			await fetchDataAsync(`${DOMAIN_URL}/zp-list`, setZillaPanchayats);
		}

		// Always fetch work types
		await fetchDataAsync(`${DOMAIN_URL}/work-types`, setWorkTypes);
		setFormData((prevFormData) => ({ ...prevFormData, ...newFormData }));
		setIsLoading(false);
	}, [fetchCurrentRole, fetchDataAsync]);

	useEffect(() => {
		const initializeComponent = async () => {
			setIsLoading(true); // Start loading
			await initializeFormData();
			setIsLoading(false); // End loading
		};

		initializeComponent();
	}, [initializeFormData]);

	const fetchJobCards = useCallback(async (villageId) => {
		try {
			const response = await fetch(
				`${DOMAIN_URL}/jobcard-list/VL/${villageId}`,
				{
					method: "GET",
					headers: getAuthHeaders(),
				}
			);

			if (!response.ok) {
				throw new Error("Network response was not ok");
			}

			const data = await response.json();
			if (data.status) {
				setJobCards(data.results);
			}
		} catch (error) {
			console.error("Error fetching job cards:", error);
		}
	}, []);

	useEffect(() => {
		if (formData.village_id) {
			fetchJobCards(formData.village_id);
		}
	}, [formData.village_id, fetchJobCards]);

	const handleInputChange = (event, newValue, reason, name) => {
		if (reason === "clear") {
			setFormData((prev) => ({ ...prev, [name]: "" }));
			if (name === "zp_id") {
				setTalukPanchayats([]);
				setGramPanchayats([]);
				setVillages([]);
				setJobCards([]);
			} else if (name === "tp_id") {
				setGramPanchayats([]);
				setVillages([]);
				setJobCards([]);
			} else if (name === "gp_id") {
				setVillages([]);
				setJobCards([]);
			} else if (name === "village_id") {
				setJobCards([]);
			}
		} else if (newValue) {
			setFormData((prev) => ({ ...prev, [name]: newValue[name] }));

			// Fetch next level data
			if (name === "zp_id") {
				fetchDataAsync(
					`${DOMAIN_URL}/tp-list/${newValue.zp_id}`,
					setTalukPanchayats
				);
				setGramPanchayats([]);
				setVillages([]);
				setJobCards([]);
			} else if (name === "tp_id") {
				fetchDataAsync(
					`${DOMAIN_URL}/gp-list/${newValue.tp_id}`,
					setGramPanchayats
				);
				setVillages([]);
				setJobCards([]);
			} else if (name === "gp_id") {
				fetchDataAsync(
					`${DOMAIN_URL}/village-list/${newValue.gp_id}`,
					setVillages
				);
				setJobCards([]);
			} else if (name === "village_id") {
				setJobCards([]);
				fetchJobCards(newValue.village_id);
			} else if (name === "work_type_id") {
				setFormData((prev) => ({
					...prev,
					[name]: newValue[name],
					job_card_id: "",
				}));
				if (newValue.work_type_id === "1" && formData.village_id) {
					fetchJobCards(formData.village_id);
				} else {
					setJobCards([]);
				}
			}
		}
	};

	const handleRemarksChange = (event) => {
		setFormData((prev) => ({ ...prev, remarks: event.target.value }));
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		if (validateForm()) {
			setShowWorkList(true);
		}
	};

	const validateForm = () => {
		if (!formData.zp_id) {
			setSnackbar({
				open: true,
				message: "Zilla Panchayat is required.",
				severity: "error",
			});
			return false;
		}
		if (!formData.tp_id) {
			setSnackbar({
				open: true,
				message: "Taluk Panchayat is required.",
				severity: "error",
			});
			return false;
		}
		if (!formData.gp_id) {
			setSnackbar({
				open: true,
				message: "Gram Panchayat is required.",
				severity: "error",
			});
			return false;
		}
		if (!formData.village_id) {
			setSnackbar({
				open: true,
				message: "Village is required.",
				severity: "error",
			});
			return false;
		}
		if (!formData.work_type_id) {
			setSnackbar({
				open: true,
				message: "Work Type is required.",
				severity: "error",
			});
			return false;
		}
		return true;
	};

	const handleNewAccountDemand = () => {
		setIsLimitReached(false);
		setFormData({
			zp_id: "",
			tp_id: "",
			gp_id: "",
			village_id: "",
			work_type_id: "",
			job_card_id: "",
			remarks: "",
		});
		requestedNewAccountDemand();
	};

	const handleWorkSelection = async (workId, options = {}) => {
		const inputData = JSON.stringify({
			state_id: 1,
			zp_id: formData.zp_id,
			tp_id: formData.tp_id,
			gp_id: formData.gp_id,
			village_id: formData.village_id,
			work_id: workId,
			work_type_id: formData.work_type_id,
			job_card_id: formData.job_card_id,
			full_name: formData.fullName,
			remarks: formData.remarks,
			raised_by: Cookies.get("admin_id") ? "LOGGEDIN" : "GUEST",
			phone_number: Cookies.get("user_name") ? Cookies.get("user_name") : "",
			...options,
		});

		const params = new URLSearchParams();
		params.append("input_data", inputData);

		try {
			const response = await fetch(`${DOMAIN_URL}/raise-demand-work`, {
				method: "POST",
				headers: getAuthHeaders(),
				body: params,
			});

			const data = await response.json();

			if (data.status) {
				const workDemandId = data.data.work_demand_id;
				// Cookies.remove("user_name");
				await fetchWorkDemandInfo(workDemandId);
			} else {
				console.error("Error raising demand:", data.message);
				setError(data.message);
				setShowWorkList(false);
			}
		} catch (error) {
			console.error("Error submitting form:", error);
			setError("An unexpected error occurred. Please try again.");
			setShowWorkList(false);
		}
	};

	const fetchWorkDemandInfo = async (workDemandId) => {
		try {
			const response = await fetch(
				`${DOMAIN_URL}/work-demand-info/${workDemandId}`,
				{
					method: "GET",
					headers: getAuthHeaders(),
				}
			);

			const data = await response.json();

			if (data.status) {
				setConfirmationData(data);
				setShowWorkList(false);
				setError(null);
				// Cookies.remove("user_name");
			} else {
				console.error("Error fetching work demand info:", data.message);
				setError(data.message);
				setShowWorkList(false);
			}
		} catch (error) {
			console.error("Error fetching work demand info:", error);
			setError("An unexpected error occurred. Please try again.");
			setShowWorkList(false);
			//   Cookies.remove("user_name");
		}
	};

	const handleCloseSnackbar = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setSnackbar({ ...snackbar, open: false });
	};

	const handleNavigateToPendingWorkList = () => {
		navigate("/core/demand-work-list");
	};

	const handleBackToRaiseDemand = () => {
		setConfirmationData(null);
		setFormData((prevFormData) => ({
			...prevFormData,
			village_id: "",
			work_type_id: "",
			job_card_id: "",
			remarks: "",
		}));
		backToRaiseNewDemandClicked();
	};

	if (error) {
		return (
			<Alert severity="error" className="max-w-2xl mx-auto mt-8">
				{error}
			</Alert>
		);
	}

	if (isLoading) {
		return (
			<div className="flex justify-center items-center h-screen">
				<CircularProgress />
			</div>
		);
	}

	if (error) {
		return (
			<Alert severity="error" className="max-w-2xl mx-auto mt-8">
				{error}
			</Alert>
		);
	}

	if (confirmationData) {
		return (
			<Confirmation
				isPublic={isPublic}
				confirmationData={confirmationData}
				handleBackToRaiseDemand={handleBackToRaiseDemand}
				handleNavigateToPendingWorkList={handleNavigateToPendingWorkList}
			/>
		);
	}

	if (showWorkList) {
		return (
			<WorkList
				onSelectWork={handleWorkSelection}
				workType={formData.work_type_id}
				jobCardId={formData.job_card_id}
				setShowWorkList={setShowWorkList}
			/>
		);
	}

	return (
		<>
			<form onSubmit={handleSubmit} className="space-y-4">
				<h6 className="text-2xl font-bold ml-10 xs:ml-40 sm:ml-0 xs:pt-2 pt-1">
					{t.raise_new_demand}
				</h6>
				{!isLimitReached && (
					<div className="grid grid-cols-1 md:grid-cols-2 gap-4">
						<Autocomplete
							id="zillaPanchayat"
							options={zillaPanchayats}
							getOptionLabel={(option) => option.zp_name || ""}
							value={
								zillaPanchayats.find((zp) => zp.zp_id === formData.zp_id) ||
								null
							}
							onChange={(event, newValue, reason) =>
								handleInputChange(event, newValue, reason, "zp_id")
							}
							renderInput={(params) => (
								<TextField
									{...params}
									label={t.zilla_panchayat}
									fullWidth
									margin="normal"
									required
								/>
							)}
							renderOption={(props, option) => (
								<li {...props} key={`zp-${option.zp_id}`}>
									{option.zp_name}
								</li>
							)}
							disabled={!!(currentRole && currentRole.zp_name)}
						/>
						<Autocomplete
							id="talukPanchayat"
							options={talukPanchayats}
							getOptionLabel={(option) => option.tp_name || ""}
							value={
								talukPanchayats.find((tp) => tp.tp_id === formData.tp_id) ||
								null
							}
							onChange={(event, newValue, reason) =>
								handleInputChange(event, newValue, reason, "tp_id")
							}
							renderInput={(params) => (
								<TextField
									{...params}
									label={t.taluk_panchayat}
									fullWidth
									margin="normal"
									required
								/>
							)}
							renderOption={(props, option) => (
								<li {...props} key={`tp-${option.tp_id}`}>
									{option.tp_name}
								</li>
							)}
							disabled={
								!formData.zp_id || !!(currentRole && currentRole.tp_name)
							}
						/>

						<Autocomplete
							id="gramPanchayat"
							options={gramPanchayats}
							getOptionLabel={(option) => option.gp_name || ""}
							value={
								gramPanchayats.find((gp) => gp.gp_id === formData.gp_id) || null
							}
							onChange={(event, newValue, reason) =>
								handleInputChange(event, newValue, reason, "gp_id")
							}
							renderInput={(params) => (
								<TextField
									{...params}
									label={t.gram_panchayat}
									fullWidth
									margin="normal"
									required
								/>
							)}
							renderOption={(props, option) => (
								<li {...props} key={`gp-${option.gp_id}`}>
									{option.gp_name}
								</li>
							)}
							disabled={
								!formData.tp_id || !!(currentRole && currentRole.gp_name)
							}
						/>

						{!villages ? (
							<div>No villages for this selection. You can proceed. </div>
						) : (
							<Autocomplete
								id="village"
								options={villages}
								getOptionLabel={(option) => option.village_name || ""}
								value={
									villages.find((v) => v.village_id === formData.village_id) ||
									null
								}
								onChange={(event, newValue, reason) =>
									handleInputChange(event, newValue, reason, "village_id")
								}
								renderInput={(params) => (
									<TextField
										{...params}
										label={t.village}
										fullWidth
										margin="normal"
										required
									/>
								)}
								renderOption={(props, option) => (
									<li {...props} key={`village-${option.village_id}`}>
										{option.village_name}
									</li>
								)}
								disabled={!formData.gp_id}
								noOptionsText="No villages available"
							/>
						)}
						<Autocomplete
							id="workType"
							options={workTypes}
							getOptionLabel={(option) => option.work_type_name || ""}
							value={
								workTypes.find(
									(wt) => wt.work_type_id === formData.work_type_id
								) || null
							}
							onChange={(event, newValue, reason) =>
								handleInputChange(event, newValue, reason, "work_type_id")
							}
							renderInput={(params) => (
								<TextField
									{...params}
									label={t.work_type}
									fullWidth
									margin="normal"
									required
								/>
							)}
							renderOption={(props, option) => (
								<li {...props} key={`workType-${option.work_type_id}`}>
									{option.work_type_name}
								</li>
							)}
						/>

						{formData.work_type_id === "1" && (
							<Autocomplete
								id="jobCard"
								options={[
									{
										job_card_id: "",
										reg_no: "",
										full_name: "Select Job Card",
									},
									...jobCards,
								]} // Added default option
								getOptionLabel={(option) =>
									`${option.reg_no} - ${option.full_name}`
								}
								value={
									jobCards.find(
										(jc) => jc.job_card_id === formData.job_card_id
									) || {
										job_card_id: "",
										reg_no: "",
										full_name: "Select Job Card",
									}
								} // Default value handling
								onChange={(event, newValue, reason) =>
									handleInputChange(event, newValue, reason, "job_card_id")
								}
								renderInput={(params) => (
									<TextField
										{...params}
										label={t.job_card}
										fullWidth
										margin="normal"
										// required
									/>
								)}
								renderOption={(props, option) => (
									<li {...props} key={`jobCard-${option.job_card_id}`}>
										{option.reg_no} - {option.full_name}
									</li>
								)}
								disabled={!formData.village_id}
							/>
						)}
						{!Cookies.get("admin_id") &&
							(formData.work_type_id !== "1" ||
								formData.job_card_id === "") && (
								<TextField
									id="fullName"
									label={t.full_name}
									value={formData.fullName || ""}
									onChange={(e) =>
										setFormData((prev) => ({
											...prev,
											fullName: e.target.value,
										}))
									}
									fullWidth
									margin="normal"
								/>
							)}
						<TextField
							id="remarks"
							label={t.remarks}
							multiline
							rows={1}
							value={formData.remarks}
							onChange={handleRemarksChange}
							fullWidth
							margin="normal"
						/>
					</div>
				)}

				<div className="flex justify-between items-center mt-4">
					<Button
						type="submit"
						variant="contained"
						color="primary"
						disabled={isLimitReached}
					>
						{t.next_step_choose_work}
					</Button>
					{isPublic && (
						<Button
							variant="outlined"
							color="primary"
							size="small"
							onClick={handleNewAccountDemand}
						>
							{t.raise_new_demand_with_another_account}
						</Button>
					)}
				</div>
			</form>
			{/* Add GuestHistory component */}
			{isPublic && Cookies.get("user_name") && (
				<div className="mt-8">
					<GuestHistory
						phoneNumber={Cookies.get("user_name") || ""}
						onLimitReached={handleLimitReached}
					/>
				</div>
			)}

			<Snackbar
				open={snackbar.open}
				autoHideDuration={6000}
				onClose={handleCloseSnackbar}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				style={{ marginTop: "80px" }}
			>
				<MuiAlert
					elevation={6}
					variant="filled"
					onClose={handleCloseSnackbar}
					severity={snackbar.severity}
				>
					{snackbar.message}
				</MuiAlert>
			</Snackbar>
		</>
	);
};

export default RaiseNewDemand;
