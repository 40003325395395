import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import Cookies from "js-cookie";

const PrivateRoute = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuth = () => {
      const adminId = Cookies.get("admin_id");
      setIsAuthenticated(!!adminId);
      setLoading(false);
    };

    checkAuth();
  }, []);

  if (loading) {
    return <div>Loading...</div>; // You can replace this with a loader component
  }

  return isAuthenticated ? <Outlet /> : <Navigate to="/auth-login" />;
};

export default PrivateRoute;
