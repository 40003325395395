import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import PreMeasurementForm from "../common/PreMeasurementForm";
import { CircularProgress, Typography, Container } from "@mui/material";
import { DOMAIN_URL, getAuthHeaders } from "utils/config";

const PremeasurementInfo = () => {
  const { work_id } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${DOMAIN_URL}/work-premeasurment-data/${work_id}`,
          {
            method: "GET",
            headers: getAuthHeaders(),
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setData(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [work_id]);

  if (loading) {
    return (
      <Container>
        <CircularProgress />
        <Typography>Loading...</Typography>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Typography color="error">Error: {error}</Typography>
      </Container>
    );
  }

  return (
    <Container>
      {data ? (
        <PreMeasurementForm
          data={data}
          onReject={() => {
            /* handle reject */
          }}
        />
      ) : null}
    </Container>
  );
};

export default PremeasurementInfo;
