import { Button } from "@mui/material";
import { FC, useEffect, useState, useCallback } from "react";
import Countdown from "react-countdown";
import theme from "./theme.module.scss";
import { generateOtp } from "../api";
import { getCurrentLanguage, translations } from "utils/translations";

type ResendOTPProps = {
	phoneNumber: string;
	otpInputRef?: any;
	newUserFlag: string;
	resetError?: () => void;
};

const ResendOTP: FC<ResendOTPProps> = ({
	phoneNumber,
	otpInputRef,
	newUserFlag,
	resetError,
}) => {
	const [timer, setTimer] = useState(2 * 60 * 1000); // Start with 2 minutes
	const [resendCount, setResendCount] = useState(0);
	const [countdownKey, setCountdownKey] = useState(0);
	const [isResendDisabled, setIsResendDisabled] = useState(true);

	const currentLanguage = getCurrentLanguage();
	const t = translations[currentLanguage];

	const handleCountdownComplete = useCallback(() => {
		setIsResendDisabled(false);
	}, []);

	const handleResendOTP = async () => {
		try {
			if (resetError) resetError();
			const newResendCount = resendCount + 1;
			setResendCount(newResendCount);
			const newTimer = Math.min(120000 * Math.pow(2, newResendCount), 300000); // Increase timer, max 5 minutes			setTimer(newTimer);
			setIsResendDisabled(true);
			setCountdownKey((prev) => prev + 1); // Force Countdown component to reset
			if (otpInputRef.current) {
				otpInputRef.current.clear();
			}
			const success = await generateOtp(phoneNumber, newUserFlag);
			if (success) {
				otpInputRef.current?.focusInput(0);
			} else {
				console.error("Failed to resend OTP");
				// You might want to show an error message to the user here
			}
		} catch (error) {
			console.error("Error resending OTP:", error);
			// You might want to show an error message to the user here
		}
	};

	return (
		<div className={theme.container}>
			<div className={`${theme.otpContainer} mt-2`}>
				<p className={theme.resendOtp}>
					{isResendDisabled ? (
						<>
							{t.otp_expires_in}{" "}
							<Countdown
								key={countdownKey}
								date={Date.now() + timer}
								onComplete={handleCountdownComplete}
							/>
						</>
					) : (
						"OTP expired"
					)}
				</p>
				<Button
					variant="outlined"
					onClick={handleResendOTP}
					disabled={isResendDisabled}
					className="my-2"
				>
					{t.resend_otp}
				</Button>
			</div>
		</div>
	);
};

export default ResendOTP;
