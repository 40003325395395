import Cookies from "js-cookie";

type User = {
	admin_id: string;
	Authenticationkey: string;
	admin_details: {
		first_name: string;
		last_name: string;
		email_address: string;
		phone_number: string;
	};
};

export const isAuthenticated = () => !!Cookies.get("token");
Cookies.set("showRegistrationDialog", !isAuthenticated());
export const showRegistrationDialog = () =>
	!!Cookies.get("showRegistrationDialog");
export const removeRegistrationDialogStatus = async () =>
	await Cookies.remove("showRegistrationDialog");

export const login = async (user: User) => {
	await Cookies.set("token", user.Authenticationkey, { expires: 365 });
	await Cookies.set("user", JSON.stringify(user), { expires: 365 });
	await Cookies.set("showRegistrationDialog", "false");
	if (window && (window as any).ReactNativeWebView) {
		(window as any).ReactNativeWebView.postMessage("LOGIN_SUCCESS");
	}
};

export const getToken = () => Cookies.get("token");
export const getUser = () =>
	Cookies.get("user") ? JSON.parse(Cookies.get("user")) : "";

export const logout = async () => {
	await Cookies.remove("token");
	await Cookies.remove("user");
	await Cookies.remove("showRegistrationDialog");
	if (window && (window as any).ReactNativeWebView) {
		(window as any).ReactNativeWebView.postMessage("LOGOUT_SUCCESS");
	}
};
