import React from "react";
import { Box, Container, Grid } from "@mui/material";
import Header from "component/Header/CommonHeader";
import Footer from "component/Footer";
import UpdatePassword from "component/UpdatePassword";

const UpdatePasswordPage = () => {
	return (
		<Box className="flex flex-col min-h-screen">
			<Header />
			<Container className="flex-grow" maxWidth="sm">
				<Grid container justifyContent="center">
					<Grid item xs={12}>
						<UpdatePassword />
					</Grid>
				</Grid>
			</Container>
			<Footer />
		</Box>
	);
};

export default UpdatePasswordPage;
