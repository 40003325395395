import React, { useState } from 'react';
import { Button } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FileUploadDialog from './FileUploadDialog';

const FileUploadButton = ({ onUploadComplete, label }) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleOpenDialog = () => setDialogOpen(true);
  const handleCloseDialog = () => setDialogOpen(false);

  return (
    <>
      <Button
        variant="contained"
        startIcon={<CloudUploadIcon />}
        onClick={handleOpenDialog}
        sx={{
          whiteSpace: 'nowrap',
          minWidth: 'auto',
          padding: '6px 16px',
        }}
      >
        {label || "Upload File"}
      </Button>
      <FileUploadDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        onUploadComplete={onUploadComplete}
      />
    </>
  );
};

export default FileUploadButton;