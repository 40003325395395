import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	TablePagination,
	TableSortLabel,
	Checkbox,
	Button,
	Snackbar,
	Alert,
	TextField,
	Typography,
	Tooltip,
	IconButton,
} from "@mui/material";
import { styled } from "@mui/system";
import { DOMAIN_URL, getAuthHeaders } from "utils/config";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import { updateWorkStatus } from "pages/dashboard/api";
import ActionButtons from "./ActionButtons";

const StyledTableContainer = styled(TableContainer)({
	maxWidth: "100%",
	overflowX: "auto",
});

const ActionPlanDraftList = () => {
	const location = useLocation();
	const [data, setData] = useState(null);
	const [order, setOrder] = useState("asc");
	const [orderBy, setOrderBy] = useState("");
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [selected, setSelected] = useState([]);
	const [snackbar, setSnackbar] = useState({
		open: false,
		message: "",
		severity: "success",
	});
	const [search, setSearch] = useState(""); // State for search query

	const fetchData = async () => {
		try {
			const fetchUrl = `${DOMAIN_URL}/actionplan-draft-list`;
			const response = await fetch(fetchUrl, {
				method: "GET",
				headers: getAuthHeaders(),
			});
			if (!response.ok) throw new Error("Network response was not ok");
			const fetchedData = await response.json();

			if (fetchedData && fetchedData.status && fetchedData.results) {
				setData(fetchedData);
				if (fetchedData.results.length > 0) {
					setOrderBy(Object.keys(fetchedData.results[0])[0]);
				}
			} else {
				throw new Error("Unexpected response structure");
			}
		} catch (error) {
			console.error("Error fetching data:", error);
			setData(null);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const handleRequestSort = (property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleActionSuccess = (message) => {
		setSnackbar({
			open: true,
			message: message,
			severity: "success",
		});
		fetchData();
	};

	const handleActionError = (message) => {
		setSnackbar({
			open: true,
			message: message,
			severity: "error",
		});
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked && data?.results) {
			const newSelecteds = data.results.map((n, i) => i);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleCheckboxClick = (event, index) => {
		event.stopPropagation();
		const selectedIndex = selected.indexOf(index);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, index);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		setSelected(newSelected);
	};

	const isSelected = (index) => selected.indexOf(index) !== -1;

	const handleAcceptClick = async (id) => {
		try {
			const response = await updateWorkStatus({
				work_demand_id: id,
				status_type: "ACCEPT",
				url: "update-apwork-status",
			});

			// Check if the response status is true for success
			if (response.status) {
				setSnackbar({
					open: true,
					message: response.message || "Accepted successfully",
					severity: "success",
				});
			} else {
				setSnackbar({
					open: true,
					message: "Failed to accept: " + (response.message || "Unknown error"),
					severity: "error",
				});
			}
			fetchData();
		} catch (error) {
			console.error("Error accepting:", error);
			setSnackbar({
				open: true,
				message: "Failed to accept",
				severity: "error",
			});
		}
	};

	const handleReject = async (id) => {
		// Implement reject logic similar to accept
	};

	// Filtered data based on search query
	const filteredResults =
		data?.results?.filter((row) => {
			return Object.values(row).some((value) =>
				String(value).toLowerCase().includes(search.toLowerCase())
			);
		}) || []; // Default to an empty array if undefined

	const showBulkActions = selected.length > 1; // Determine if bulk actions should be shown

	const showAcceptButtons = data?.actionFlag !== false;

	return (
		<Paper sx={{ width: "100%", mb: 2 }}>
			<Typography
				variant="h6"
				component="div"
				className="text-2xl font-bold ml-10 xs:ml-40 sm:ml-4  xs:pt-2 pt-1"
			>
				Action Plan Draft List
			</Typography>
			<div className="flex justify-between items-center mb-2 mt-2">
				{showBulkActions && ( // Show bulk action buttons if more than one row is selected
					<div className="flex space-x-2">
						{showAcceptButtons && (
							<Button
								variant="contained"
								color="success"
								onClick={() => selected.forEach((id) => handleAcceptClick(id))}
							>
								Accept All
							</Button>
						)}
						<Button
							variant="contained"
							color="error"
							onClick={() => selected.forEach((id) => handleReject(id))}
						>
							Reject All
						</Button>
					</div>
				)}
				<div className="ml-auto">
					{" "}
					{/* Added this div to push the TextField to the right */}
					<TextField
						variant="outlined"
						placeholder="Search…"
						value={search}
						onChange={(e) => setSearch(e.target.value)}
						className="w-full sm:w-64 mr-2"
					/>
				</div>
			</div>
			<StyledTableContainer>
				<Table stickyHeader aria-label="sticky table">
					<TableHead>
						<TableRow>
							<TableCell padding="checkbox">
								<Checkbox
									indeterminate={
										selected.length > 0 &&
										selected.length < (filteredResults?.length || 0)
									}
									checked={
										filteredResults?.length > 0 &&
										selected.length === filteredResults.length
									}
									onChange={handleSelectAllClick}
									inputProps={{ "aria-label": "select all desserts" }}
								/>
							</TableCell>
							<TableCell className="bg-slate-100 text-dark-600 font-semibold align-top">
								{!showBulkActions && "Actions"}{" "}
								{/* Hide actions header if bulk actions are shown */}
							</TableCell>
							{filteredResults[0] &&
								Object.keys(filteredResults[0])
									.filter((key) => !key.includes("_id"))
									.map((key) => (
										<TableCell
											key={key}
											sortDirection={orderBy === key ? order : false}
											className="bg-slate-100 text-dark-600 font-semibold align-top"
										>
											<TableSortLabel
												active={orderBy === key}
												direction={orderBy === key ? order : "asc"}
												onClick={() => handleRequestSort(key)}
											>
												{key.replaceAll("_", " ").toUpperCase()}
											</TableSortLabel>
										</TableCell>
									))}
						</TableRow>
					</TableHead>
					<TableBody>
						{filteredResults
							?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							.map((row, index) => {
								const isItemSelected = isSelected(index);
								return (
									<TableRow
										hover
										role="checkbox"
										aria-checked={isItemSelected}
										tabIndex={-1}
										key={`${row.work_demand_id}-${index}`}
										selected={isItemSelected}
									>
										<TableCell padding="checkbox">
											<Checkbox
												checked={isItemSelected}
												onChange={(event) => handleCheckboxClick(event, index)}
												inputProps={{
													"aria-labelledby": `enhanced-table-checkbox-${index}`,
												}}
											/>
										</TableCell>
										{!showBulkActions && ( // Hide action buttons if bulk actions are shown
											<TableCell>
												<ActionButtons
													workDemandId={row.work_demand_id}
													onSuccess={handleActionSuccess}
													onError={handleActionError}
													showAcceptButton={showAcceptButtons}
												/>
											</TableCell>
										)}
										{Object.keys(row)
											.filter((key) => !key.includes("_id"))
											.map((key) => (
												<TableCell key={`${index}-${key}`}>
													{row[key]}
												</TableCell>
											))}
									</TableRow>
								);
							})}
					</TableBody>
				</Table>
			</StyledTableContainer>
			<TablePagination
				rowsPerPageOptions={[5, 10, 25]}
				component="div"
				count={filteredResults?.length || 0}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
			<Snackbar
				open={snackbar.open}
				autoHideDuration={3000}
				onClose={() => setSnackbar({ ...snackbar, open: false })}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<Alert
					onClose={() => setSnackbar({ ...snackbar, open: false })}
					severity={snackbar.severity}
				>
					{snackbar.message}
				</Alert>
			</Snackbar>
		</Paper>
	);
};

export default ActionPlanDraftList;
