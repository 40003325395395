import { DOMAIN_URL, getAuthHeaders } from "utils/config";

interface SubCategoryResponse {
    status: boolean;
    results: any[];
}

export const fetchSubCategories = async (categoryId: string): Promise<SubCategoryResponse> => {
    try {
        const response = await fetch(`${DOMAIN_URL}/work-sub-category?category_id=${categoryId}`, {
            headers: getAuthHeaders(),
        });

        if (!response.ok) {
            const errorText = await response.text();
            console.error("Response error:", errorText);
            throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
        }

        const data: SubCategoryResponse = await response.json();
        if (!data.status) {
            throw new Error("Failed to fetch sub-categories");
        }

        return data;
    } catch (error) {
        console.error("Error fetching sub-categories:", error);
        throw error;
    }
};