import React from "react";
import RaiseNewDemand from "component/RaiseNewDemand";
import Header from "component/Header/CommonHeader";
import Footer from "component/Footer";

function RaiseDemandWork() {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="flex-grow container mx-auto p-4">
        <RaiseNewDemand />
      </main>
      <Footer />
    </div>
  );
}

export default RaiseDemandWork;
