import React, { useState } from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
	CircularProgress,
	Typography,
	useTheme,
	useMediaQuery,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/system";
import { uploadActionPlan } from "../pages/dashboard/api";

const Input = styled("input")({
	display: "none",
});

const FileUploadDialog = ({ open, onClose, onUploadComplete }) => {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const [file, setFile] = useState(null);
	const [remarks, setRemarks] = useState("");
	const [commencementDate, setCommencementDate] = useState("");
	const [uploading, setUploading] = useState(false);
	const [previewUrl, setPreviewUrl] = useState("");

	const handleFileChange = (event) => {
		const selectedFile = event.target.files[0];
		setFile(selectedFile);

		// Create preview URL for supported file types
		if (selectedFile && selectedFile.type.startsWith("image/")) {
			setPreviewUrl(URL.createObjectURL(selectedFile));
		} else {
			setPreviewUrl("");
		}
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		if (!file) {
			onUploadComplete(false, "Please select a file to upload.");
			return;
		}

		const fileType = file.type;
		const fileSize = file.size;

		// Validate file type
		const validTypes = [
			"application/pdf",
			"application/msword",
			"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
			"application/vnd.ms-excel",
			"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
		];

		if (!validTypes.includes(fileType)) {
			onUploadComplete(
				false,
				"Invalid file type. Please upload a PDF, Word, or Excel file."
			);
			return;
		}

		// Validate file size (5MB max)
		const maxSize = 5 * 1024 * 1024;
		if (fileSize > maxSize) {
			onUploadComplete(
				false,
				"File size exceeds 5MB. Please upload a smaller file."
			);
			return;
		}

		setUploading(true);
		try {
			const response = await uploadActionPlan(file, remarks, commencementDate);
			if (response.status) {
				onUploadComplete(true, "File uploaded successfully!");
				onClose();
				window.location.reload();
			} else {
				throw new Error("File upload failed");
			}
		} catch (error) {
			console.error("Error uploading file:", error);
			onUploadComplete(false, "File upload failed.");
		} finally {
			setUploading(false);
		}
	};

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullScreen={fullScreen}
			maxWidth="sm"
			fullWidth={fullScreen}
			PaperProps={{
				sx: {
					minWidth: { md: "500px" },
				},
			}}
		>
			<DialogTitle>Upload Action Plan</DialogTitle>
			<form onSubmit={handleSubmit} method="POST" encType="multipart/form-data">
				<DialogContent>
					<label htmlFor="file-upload">
						<Input
							id="file-upload"
							name="file"
							type="file"
							onChange={handleFileChange}
							accept=".pdf,.doc,.docx,.xls,.xlsx"
						/>
						<Button
							component="span"
							variant="contained"
							startIcon={<CloudUploadIcon />}
							sx={{ mb: 2 }}
						>
							Select File
						</Button>
					</label>
					{file && (
						<Typography variant="body2" sx={{ mb: 2 }}>
							Selected file: {file.name}
						</Typography>
					)}
					{previewUrl && (
						<img
							src={previewUrl}
							alt="Preview"
							style={{
								maxWidth: "100%",
								maxHeight: "200px",
								marginBottom: "16px",
							}}
						/>
					)}
					<TextField
						label="Commencement Date"
						type="date"
						fullWidth
						value={commencementDate}
						onChange={(e) => setCommencementDate(e.target.value)}
						InputLabelProps={{
							shrink: true,
						}}
						sx={{ mb: 2 }}
					/>
					<TextField
						label="Remarks"
						name="remarks"
						multiline
						rows={1}
						fullWidth
						value={remarks}
						onChange={(e) => setRemarks(e.target.value)}
						sx={{ mb: 2 }}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={onClose}>Cancel</Button>
					<Button
						type="submit"
						variant="contained"
						disabled={uploading || !file}
						startIcon={uploading ? <CircularProgress size={20} /> : null}
					>
						{uploading ? "Uploading..." : "Submit"}
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};

export default FileUploadDialog;
