import React from "react";
// import { Link } from "react-router-dom";

import StrategyImage from "../assets/images/svg/design-thinking.svg";
import departmentImage from "../assets/images/svg/coding.svg";
import launchImage from "../assets/images/svg/office-desk.svg";
import { getCurrentLanguage, translations } from "utils/translations";

export default function Services() {
	const currentLanguage = getCurrentLanguage();
	const t = translations[currentLanguage];

	return (
		<>
			{/* Start */}
			<section className="relative md:py-24 py-16 active" id="features">
				<div className="container md:mt-24 mt-16">
					<div className="grid grid-cols-1 pb-8 text-center">
						<h6 className="text-orange-600 text-base font-medium uppercase mb-2">
							{t.work_process}
						</h6>
						<h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">
							{t.mgnrega_flow}
						</h3>

						<p className="text-slate-400 dark:text-slate-300 max-w-xl mx-auto">
							{t.workflow_description}
						</p>
					</div>

					<div className="grid grid-cols-1 mt-8">
						<div className="timeline relative">
							<div className="timeline-item">
								<div className="grid sm:grid-cols-2">
									<div className="">
										<div className="duration date-label-left ltr:float-right rtl:float-left md:me-7 relative">
											<img src={StrategyImage} className="h-64 w-64" alt="" />
										</div>
									</div>
									<div className="mt-4 md:mt-0">
										<div className="event event-description-right ltr:float-left rtl:float-right ltr:text-left rtl:text-right md:ms-7">
											<h5 className="text-lg dark:text-white mb-1 font-medium">
												{t.planning_budget_allocation}
											</h5>
											<p className="timeline-subtitle mt-3 mb-0 text-slate-400">
												{t.planning_description}
											</p>
										</div>
									</div>
								</div>
							</div>

							<div className="timeline-item mt-5 pt-4">
								<div className="grid sm:grid-cols-2">
									<div className="md:order-1 order-2">
										<div className="event event-description-left ltr:float-left rtl:float-right ltr:text-right rtl:text-left md:me-7">
											<h5 className="text-lg dark:text-white mb-1 font-medium">
												{t.job_demand_registration}
											</h5>
											<p className="timeline-subtitle mt-3 mb-0 text-slate-400">
												{t.job_demand_description}
											</p>
										</div>
									</div>
									<div className="md:order-2 order-1">
										<div className="duration duration-right md:ms-7 relative">
											<img src={departmentImage} className="h-64 w-64" alt="" />
										</div>
									</div>
								</div>
							</div>

							<div className="timeline-item mt-5 pt-4">
								<div className="grid sm:grid-cols-2">
									<div className="mt-4 mt-sm-0">
										<div className="duration date-label-left ltr:float-right rtl:float-left md:me-7 relative">
											<img src={launchImage} className="h-64 w-64" alt="" />
										</div>
									</div>
									<div className="mt-4 mt-sm-0">
										<div className="event event-description-right ltr:float-left rtl:float-right ltr:text-left rtl:text-right md:ms-7">
											<h5 className="text-lg dark:text-white mb-1 font-medium">
												{t.work_allocation}
											</h5>
											<p className="timeline-subtitle mt-3 mb-0 text-slate-400">
												{t.work_allocation_description}
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}
