import React, { useState, useEffect } from "react";
import {
	TextField,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	Typography,
	Button,
	Grid,
	Paper,
	Snackbar,
} from "@mui/material";
import { completePreMeasurement } from "../api";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import ArrowBack from "@mui/icons-material/ArrowBack";
import JobCardSelector from "./PreMeasurement/JobCardSelector";
import WorkFilters from "component/WorkFilters";

const PreMeasurementForm = ({ data, onReject }) => {
	const [formData, setFormData] = useState({});
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const navigate = useNavigate();
	const location = useLocation();
	const [amountError, setAmountError] = useState("");
	const [uploadedPhoto, setUploadedPhoto] = useState(null); // Add state for uploaded photo
	const [fileError, setFileError] = useState(""); // State for file error message
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [uploadedPhotoUrl, setUploadedPhotoUrl] = useState(null);
	const [selectedJobCard, setSelectedJobCard] = useState(null);
	const [workFilters, setWorkFilters] = useState({
		category: data.results.work_sc1_id || "",
		subType1: data.results.work_sc2_id || "",
		subType2: data.results.work_sc3_id || "",
		subType3: data.results.work_sc4_id || "",
		subType4: data.results.work_sc5_id || "",
	});

	useEffect(() => {
		// Reset form data when the location changes
		setFormData({});
		// Add any other reset logic here

		// Autofill form data if results.form_data is available
		if (data.results.form_data) {
			const { form_data } = data.results;

			// Autofill basic fields
			setFormData({
				variable_amount: form_data.estimated_amount || "",
				work_name: form_data.work_name || "",
				address: form_data.address || "",
			});

			// Autofill gQuestion fields
			if (form_data.gQuestion) {
				form_data.gQuestion.forEach((question) => {
					const uniqueId = question.uid;
					setFormData((prevData) => ({
						...prevData,
						[uniqueId]: question.selected_value || question.text_value || "",
						[`${uniqueId}_number`]: question.number || "",
						[`${uniqueId}_length`]: question.length || "",
						[`${uniqueId}_width`]: question.width || "",
						[`${uniqueId}_depth`]: question.depth || "",
					}));
				});
			}

			// Autofill iQuestion fields if they exist
			if (form_data.iQuestion) {
				form_data.iQuestion.forEach((question) => {
					const uniqueId = question.uid;
					setFormData((prevData) => ({
						...prevData,
						[uniqueId]: question.selected_value || question.text_value || "",
						[`${uniqueId}_number`]: question.number || "",
						[`${uniqueId}_length`]: question.length || "",
						[`${uniqueId}_width`]: question.width || "",
						[`${uniqueId}_depth`]: question.depth || "",
					}));
				});
			}

			// Autofill uploaded photo
			if (data.uploads && form_data.upload_file) {
				const photoUrl = `${data.uploads}${form_data.upload_file}`;
				setUploadedPhotoUrl(photoUrl);
				// Create a File object from the URL
				fetch(photoUrl)
					.then((res) => res.blob())
					.then((blob) => {
						const file = new File([blob], form_data.upload_file, {
							type: blob.type,
						});
						setUploadedPhoto(file);
					})
					.catch((error) =>
						console.error("Error fetching uploaded photo:", error)
					);
			}

			// Autofill JobCard
			if (form_data.job_card_id) {
				setFormData((prevData) => ({
					...prevData,
					job_card_id: form_data.job_card_id,
				}));
				setSelectedJobCard({
					job_card_id: form_data.job_card_id,
					job_card_number: form_data.job_card_number,
				});
			}
		}
	}, [
		location.pathname,
		data.results.form_data,
		data.uploads,
		data.results.form_data,
	]);

	useEffect(() => {
		requestLocation(); // Request location on component mount
	}, []); // Empty dependency array ensures this runs on mount

	const requestLocation = (callback) => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					const { latitude, longitude } = position.coords;
					handleLocationChange(latitude, longitude);
					if (callback) callback(); // Call the callback if provided
				},
				(error) => {
					console.error("Error obtaining location: ", error);
					if (error.code === error.PERMISSION_DENIED) {
						setSnackbarMessage(
							"Please allow location access for better functionality."
						);
						setSnackbarOpen(true); // Open snackbar for warning
					}
					if (callback) callback(); // Call the callback if provided
				}
			);
		} else {
			console.error("Geolocation is not supported by this browser.");
			if (callback) callback(); // Call the callback if provided
		}
	};

	const handleLocationChange = (latitude, longitude) => {
		// Update state or perform actions with the location data
		setFormData((prevData) => ({
			...prevData,
			latitude: latitude,
			longitude: longitude,
		}));
	};

	const handleInputChange = (uid, value) => {
		setFormData((prevData) => ({
			...prevData,
			[uid]: value,
		}));

		// Validate amount if it's a variable amount type
		if (uid === "variable_amount" && results.amount_type === "VARIABLE") {
			const maxEstimation = Number(results.max_estimation);
			if (Number(value) > maxEstimation) {
				setAmountError(`Amount cannot exceed ${maxEstimation}`);
			} else {
				setAmountError("");
			}
		}
	};

	const handleJobCardSelect = (selectedJobCard) => {
		if (selectedJobCard) {
			setFormData((prevData) => ({
				...prevData,
				job_card_id: selectedJobCard.job_card_id,
			}));
			setSelectedJobCard(selectedJobCard);
		}
	};

	const renderField = (question, questionType) => {
		const uniqueId = question.uid;

		switch (question.field_type) {
			case "number":
				return (
					<Grid container spacing={2}>
						<Grid item xs={12} md={question.unit_type ? 10 : 12}>
							<TextField
								type="number"
								fullWidth
								id={uniqueId}
								value={formData[uniqueId] || ""}
								onChange={(e) => handleInputChange(uniqueId, e.target.value)}
							/>
						</Grid>
						{question.unit_type && (
							<Grid item xs={12} md={2}>
								<Typography>{question.unit_type}</Typography>
							</Grid>
						)}
					</Grid>
				);
			case "LV":
				return (
					<Grid container spacing={2}>
						<Grid item xs={12} md={5}>
							<TextField
								type="number"
								label="Length"
								fullWidth
								id={`${uniqueId}_length`}
								value={formData[`${uniqueId}_length`] || ""}
								onChange={(e) =>
									handleInputChange(`${uniqueId}_length`, e.target.value)
								}
							/>
						</Grid>
						<Grid item xs={12} md={5}>
							<TextField
								type="number"
								label="Width"
								fullWidth
								id={`${uniqueId}_width`}
								value={formData[`${uniqueId}_width`] || ""}
								onChange={(e) =>
									handleInputChange(`${uniqueId}_width`, e.target.value)
								}
							/>
						</Grid>
						{question.unit_type && (
							<Grid item xs={12} md={2}>
								<Typography>{question.unit_type}</Typography>
							</Grid>
						)}
					</Grid>
				);
			case "LVD":
				return (
					<Grid container spacing={2}>
						<Grid item xs={12} md={3}>
							<TextField
								type="number"
								label="Length"
								fullWidth
								id={`${uniqueId}_length`}
								value={formData[`${uniqueId}_length`] || ""}
								onChange={(e) =>
									handleInputChange(`${uniqueId}_length`, e.target.value)
								}
							/>
						</Grid>
						<Grid item xs={12} md={3}>
							<TextField
								type="number"
								label="Width"
								fullWidth
								id={`${uniqueId}_width`}
								value={formData[`${uniqueId}_width`] || ""}
								onChange={(e) =>
									handleInputChange(`${uniqueId}_width`, e.target.value)
								}
							/>
						</Grid>
						<Grid item xs={12} md={3}>
							<TextField
								type="number"
								label="Depth"
								fullWidth
								id={`${uniqueId}_depth`}
								value={formData[`${uniqueId}_depth`] || ""}
								onChange={(e) =>
									handleInputChange(`${uniqueId}_depth`, e.target.value)
								}
							/>
						</Grid>
						{question.unit_type && (
							<Grid item xs={12} md={3}>
								<Typography>{question.unit_type}</Typography>
							</Grid>
						)}
					</Grid>
				);
			case "text":
			case "comment":
				return (
					<TextField
						fullWidth
						id={uniqueId}
						value={formData[uniqueId] || ""}
						onChange={(e) => handleInputChange(uniqueId, e.target.value)}
					/>
				);
			case "textarea":
				return (
					<TextField
						fullWidth
						multiline
						rows={4}
						id={uniqueId}
						value={formData[uniqueId] || ""}
						onChange={(e) => handleInputChange(uniqueId, e.target.value)}
					/>
				);
			case "list":
				return (
					<FormControl fullWidth>
						<InputLabel id={`select-label-${uniqueId}`}>
							{question.question}
						</InputLabel>
						<Select
							labelId={`select-label-${uniqueId}`}
							id={uniqueId}
							label={question.question}
							value={formData[uniqueId] || ""}
							onChange={(e) => handleInputChange(uniqueId, e.target.value)}
						>
							{question.select_list?.map((option) => (
								<MenuItem key={option} value={option}>
									{option}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				);
			default:
				return null;
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setIsSubmitting(true); // Disable the button
		requestLocation(proceedWithSubmission);
	};

	const proceedWithSubmission = async () => {
		const params = {
			work_demand_id: parseInt(data.results.work_demand_id),
			estimated_amount: formData.variable_amount || "",
			upload_file_old: data.form_data?.upload_file || "",
			latitude: formData.latitude || "",
			longitude: formData.longitude || "",
			work_name: formData.work_name || "",
			address: formData.address || "",
			job_card_id: formData.job_card_id || "",
			drive_tag_id: formData.drive_tag_id || "",
			gQuestion: data.results.gQuestion
				? data.results.gQuestion.map((q) => {
						return {
							work_group_id: q.work_group_id,
							group_question_id: q.group_question_id,
							measurement_id: q.measurement_id,
							selected_value: formData[q.uid] || "",
							number: formData[`${q.uid}_number`] || "",
							length: formData[`${q.uid}_length`] || "",
							width: formData[`${q.uid}_width`] || "",
							depth: formData[`${q.uid}_depth`] || "",
							text_value: formData[q.uid] || "",
							uid: q.uid,
						};
				  })
				: [],
			iQuestion: data.results.iQuestion
				? data.results.iQuestion.map((q) => {
						return {
							work_question_id: q.work_question_id,
							group_question_id: q.group_question_id,
							measurement_id: q.measurement_id,
							selected_value: formData[q.uid] || "",
							number: formData[`${q.uid}_number`] || "",
							length: formData[`${q.uid}_length`] || "",
							width: formData[`${q.uid}_width`] || "",
							depth: formData[`${q.uid}_depth`] || "",
							text_value: formData[q.uid] || "",
							uid: q.uid,
						};
				  })
				: [],
			work_sc1_id: workFilters.category,
			work_sc2_id: workFilters.subType1,
			work_sc3_id: workFilters.subType2,
			work_sc4_id: workFilters.subType3,
			work_sc5_id: workFilters.subType4,
		};

		try {
			const result = await completePreMeasurement(params, uploadedPhoto); // Pass the uploaded photo
			setSnackbarMessage("Pre-Measurement request submitted successfully");
			setSnackbarOpen(true);

			setTimeout(() => {
				window.location.href = "/core/approved-demand-list";
			}, 2000);
		} catch (error) {
			console.error("Error submitting pre-measurement form:", error);
			setIsSubmitting(false); // Re-enable the button if there's an error
			// Handle error (e.g., show error message to user)
		}
	};

	const handleSnackbarClose = () => {
		setSnackbarOpen(false);
		setSnackbarMessage(""); // Reset snackbar message
	};

	const handleFileChange = (event) => {
		const selectedFile = event.target.files[0];

		if (selectedFile) {
			// Validate file size (5MB = 5 * 1024 * 1024 bytes)
			if (selectedFile.size > 5 * 1024 * 1024) {
				setFileError("File size exceeds 5MB. Please upload a smaller file.");
				setUploadedPhoto(null); // Reset uploaded photo
			} else {
				setFileError(""); // Clear any previous error
				setUploadedPhoto(selectedFile); // Set the uploaded photo
			}
		}
	};

	const handleFilterChange = (filters) => {
		setWorkFilters(filters);
	};

	const { results } = data;
	const BASE_IMAGE_URL = data.img_path;

	return (
		<Paper elevation={3} style={{ padding: "20px", margin: "20px" }}>
			<Typography
				variant="h4"
				gutterBottom
				className="text-2xl font-bold ml-10 xs:ml-40 sm:ml-0 xs:pt-2 pt-1"
			>
				Pre-Measurement Form
			</Typography>
			<Typography variant="h6" gutterBottom className="font-bold">
				Selected Work Details
			</Typography>
			<div className="flex flex-col mb-6 xs:flex-row">
				<div className="w-full mb-4 xs:w-1/2 xs:mr-4">
					{results.work_image && (
						<img
							src={`${BASE_IMAGE_URL}${results.work_image}`}
							alt={results.work_title}
							className="w-full h-auto mb-4 max-h-[300px] object-fill"
						/>
					)}
				</div>
				<div className="w-full xs:w-1/2">
					<Typography variant="body1" gutterBottom>
						<strong>Work Demand Code :</strong> {results.work_code}
					</Typography>

					<Typography variant="body1" gutterBottom>
						<strong>Work Title:</strong> {results.work_title}
					</Typography>

					<Typography variant="body1" gutterBottom>
						<strong>Category Title:</strong> {results.category_title}
					</Typography>

					<Typography>
						<strong>Work Type:</strong> {results.work_type_name}
					</Typography>
					<Typography>
						<strong>Description:</strong> {results.description}
					</Typography>
					<hr className="my-2.5 border-t border-gray-200" />
					<Typography>
						<strong>Amount type:</strong> {results.amount_type}
					</Typography>
					<Typography>
						<strong>Wage:</strong>
						{results.amount_type === "FIXED"
							? ` ₹${results.wage}`
							: ` ${results.wage}%`}
					</Typography>
					<Typography>
						<strong>Material:</strong>
						{results.amount_type === "FIXED"
							? ` ₹${results.material}`
							: ` ${results.material}%`}
					</Typography>
				</div>
			</div>
			<hr className="my-4 border-t border-gray-300" />
			{results.reason && (
				<>
					<Typography
						variant="body1"
						gutterBottom
						className="bg-amber-100 p-3 rounded-md mb-4"
					>
						{results.reason}
					</Typography>
					<hr className="my-4 border-t border-gray-300" />
				</>
			)}
			<form onSubmit={handleSubmit} method="POST" encType="multipart/form-data">
				<Typography variant="h6" gutterBottom className="font-bold">
					Work Categories
				</Typography>
				<WorkFilters
					onFilterChange={handleFilterChange}
					initialFilters={{
						category: data.results.work_sc1_id,
						subType1: data.results.work_sc2_id,
						subType2: data.results.work_sc3_id,
						subType3: data.results.work_sc4_id,
						subType4: data.results.work_sc5_id,
					}}
				/>
				<hr className="my-4 border-t border-gray-300" />
				{(results.gQuestion ||
					results.iQuestion ||
					results.amount_type === "VARIABLE") && (
					<Typography variant="h6" gutterBottom className="font-bold">
						Questionnaire
					</Typography>
				)}
				{results.amount_type === "VARIABLE" && (
					<Grid container spacing={2} style={{ marginBottom: "20px" }}>
						<Grid item xs={12} md={10}>
							<Typography variant="subtitle1">Estimated Amount</Typography>
						</Grid>
						<Grid item xs={12} md={10}>
							<TextField
								type="number"
								fullWidth
								id="variable_amount"
								value={formData.variable_amount || ""}
								onChange={(e) =>
									handleInputChange("variable_amount", e.target.value)
								}
								error={!!amountError}
								// helperText={
								// 	amountError || `Max estimation: ₹${results.max_estimation}`
								// }
							/>
						</Grid>
						<Grid item xs={12} md={2}>
							<Typography>₹</Typography>
						</Grid>
					</Grid>
				)}
				{/* Render gQuestion fields */}
				{results.gQuestion &&
					results.gQuestion.map((question) => (
						<Grid
							container
							spacing={2}
							key={question.uid}
							style={{ marginBottom: "20px" }}
						>
							<Grid item xs={12}>
								<Typography variant="subtitle1">{question.question}</Typography>
							</Grid>
							<Grid item xs={12}>
								{renderField(question, "gQuestion")}
							</Grid>
						</Grid>
					))}
				{/* Render iQuestion fields */}
				{results.iQuestion &&
					results.iQuestion.map((question) => (
						<Grid
							container
							spacing={2}
							key={question.uid}
							style={{ marginBottom: "20px" }}
						>
							<Grid item xs={12}>
								<Typography variant="subtitle1">{question.question}</Typography>
							</Grid>
							<Grid item xs={12}>
								{renderField(question, "iQuestion")}
							</Grid>
						</Grid>
					))}
				<Grid container spacing={2} style={{ marginBottom: "20px" }}>
					<Grid item xs={12}>
						<Typography variant="subtitle1">Upload Photo</Typography>
					</Grid>
					<Grid item xs={12}>
						<TextField
							type="file"
							accept="image/*"
							onChange={handleFileChange}
							fullWidth
							error={!!fileError} // Show error state if there's an error
							helperText={fileError} // Display error message
							inputProps={{ capture: "user" }} // Capture from user device
						/>
						{uploadedPhotoUrl && (
							<img
								src={uploadedPhotoUrl}
								alt="Uploaded Photo"
								style={{
									maxWidth: "100%",
									maxHeight: "200px",
									marginTop: "10px",
								}}
							/>
						)}
					</Grid>
				</Grid>
				<Grid container spacing={2} style={{ marginBottom: "20px" }}>
					<Grid item xs={12}>
						<Typography variant="subtitle1">Work Name</Typography>
					</Grid>
					<Grid item xs={12}>
						<TextField
							fullWidth
							id="work_name"
							value={formData.work_name || ""}
							onChange={(e) => handleInputChange("work_name", e.target.value)}
						/>
					</Grid>
				</Grid>
				{/* <Grid container spacing={2} style={{ marginBottom: "20px" }}>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Location</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="location"
              value={formData.address || ""} // Assuming address is 
              stored in formData
              onChange={(e) => handleInputChange("address", e.target.
              value)}
            />
          </Grid>
        </Grid> */}

				{results.drive_tags && results.drive_tags.length > 0 && (
					<Grid container spacing={2} style={{ marginBottom: "20px" }}>
						<Grid item xs={12}>
							<Typography variant="subtitle1">Select Special Drive</Typography>
						</Grid>
						<Grid item xs={12}>
							<FormControl fullWidth>
								<InputLabel id="select-drive-tag-label">
									Special Drive
								</InputLabel>
								<Select
									labelId="select-drive-tag-label"
									id="select-drive-tag"
									value={formData.drive_tag_id || ""}
									onChange={(e) =>
										handleInputChange("drive_tag_id", e.target.value)
									}
								>
									{results.drive_tags.map((tag) => (
										<MenuItem key={tag.drive_tag_id} value={tag.drive_tag_id}>
											{tag.drive_tag_name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
					</Grid>
				)}

				{results.work_type_id === "1" && !results.job_card_id && (
					<Grid container spacing={2} style={{ marginBottom: "20px" }}>
						<Grid item xs={12}>
							<Typography variant="subtitle1">Select Job Card</Typography>
						</Grid>
						<Grid item xs={12}>
							<JobCardSelector
								villageId={results.village_id}
								onJobCardSelect={handleJobCardSelect}
								selectedJobCard={selectedJobCard}
							/>
						</Grid>
					</Grid>
				)}
				{!location.pathname.includes("core/premeasurement-data") && (
					<Grid container justifyContent="space-between">
						<Button
							variant="outlined"
							className="mr-2"
							color="primary"
							startIcon={<ArrowBack />}
							onClick={() => window.location.reload()}
						>
							Back
						</Button>
						{/* <Button
            variant="contained"
            className="mr-2"
            color="error"
            onClick={() => onReject(data.results.work_demand_id)}
          >
            Reject
          </Button> */}
						{/* TODO: Implement reject logic here */}
						<Button
							type="submit"
							variant="contained"
							color="success"
							disabled={!!amountError || isSubmitting}
						>
							{isSubmitting ? "Submitting..." : "Submit"}
						</Button>
					</Grid>
				)}
			</form>
			<Snackbar
				open={snackbarOpen}
				autoHideDuration={6000}
				onClose={handleSnackbarClose}
				message={snackbarMessage}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				ContentProps={{
					sx: {
						backgroundColor: "white",
						color: "primary.main",
						border: "1px solid",
						borderColor: "primary.main",
						marginTop: "80px",
					},
				}}
			/>
		</Paper>
	);
};

export default PreMeasurementForm;
