import React from "react";
import {
	Card,
	CardContent,
	CardMedia,
	Typography,
	Button,
	Alert,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import Cookies from "js-cookie";
import { getCurrentLanguage, translations } from "utils/translations";

const Confirmation = ({
	isPublic,
	confirmationData,
	handleBackToRaiseDemand,
	handleNavigateToPendingWorkList,
}) => {
	const { results, img_path } = confirmationData;
	const currentLanguage = getCurrentLanguage();
	const t = translations[currentLanguage];

	const subcategories = [
		{
			id: results.work_sc1_id,
			name: results.work_sc1_name,
			label: "Simplified Work category",
		},
		{
			id: results.work_sc2_id,
			name: results.work_sc2_name,
			label: "Work Sub-type-I",
		},
		{
			id: results.work_sc3_id,
			name: results.work_sc3_name,
			label: "Work Sub-type-II",
		},
		{
			id: results.work_sc4_id,
			name: results.work_sc4_name,
			label: "Work Sub-type-III",
		},
		{
			id: results.work_sc5_id,
			name: results.work_sc5_name,
			label: "Work Sub-type-IV",
		},
	];

	const handleNewAccountDemand = () => {
		Cookies.remove("user_name");
		handleBackToRaiseDemand();
	};

	return (
		<Card className="max-w-4xl mt-8" style={{ border: "2px solid #4CAF50" }}>
			<CardContent>
				<div className="flex justify-between items-center mb-4">
					<Typography
						variant="h5"
						component="div"
						className="font-bold"
						style={{ color: "#4CAF50" }}
					>
						{t.demand_sucsess}
					</Typography>
				</div>
				<hr className="my-4 border-t border-gray-300" />
				<div className="flex">
					<CardMedia
						component="img"
						style={{
							width: "40%",
							height: "auto",
							maxHeight: "300px",
							marginRight: "16px",
						}}
						image={`${img_path}${results.work_image}`}
						alt={results.work_title}
					/>
					<div>
						<div
							style={{
								backgroundColor: "#FFEB3B",
								padding: "4px 8px",
								marginBottom: "8px",
							}}
						>
							<Typography variant="body1" gutterBottom>
								<strong>{t.work_demand_code}:</strong> {results.work_code}
							</Typography>
						</div>
						<Typography variant="body1" gutterBottom>
							<strong>{t.work_title}:</strong> {results.work_title}
						</Typography>
						<Typography variant="body1" gutterBottom>
							<strong>{t.category_title}:</strong> {results.category_title}
						</Typography>
						{subcategories.map(
							(subcat, index) =>
								subcat.id &&
								subcat.name && (
									<Typography key={index} variant="body1" gutterBottom>
										<strong>{subcat.label}:</strong> {subcat.name}
									</Typography>
								)
						)}
						<Typography variant="body1" gutterBottom>
							<strong>{t.work_type}:</strong> {results.work_type_name}
						</Typography>
						<Typography variant="body1" gutterBottom>
							<strong>{t.wage}:</strong> ₹{results.wage}
						</Typography>
						<Typography variant="body1" gutterBottom>
							<strong>{t.material}:</strong> ₹{results.material}
						</Typography>
						<Typography variant="body1" gutterBottom>
							<strong>{t.description_label}:</strong> {results.description}
						</Typography>
					</div>
				</div>
				<div className="space-x-2 flex justify-between mt-5">
					<Button
						variant="outlined"
						startIcon={<ArrowBack />}
						size="small"
						onClick={handleBackToRaiseDemand}
					>
						{t.back_to_raise_new_demand}
					</Button>
					{!isPublic && Cookies.get("admin_id") && (
						<Button
							variant="outlined"
							color="primary"
							size="small"
							onClick={handleNavigateToPendingWorkList}
						>
							{t.go_to_pending_work_list}
						</Button>
					)}
				</div>
				<Button
					variant="outlined"
					color="secondary"
					size="small"
					onClick={handleNewAccountDemand}
					style={{ marginTop: "10px" }}
				>
					{t.raise_new_demand_with_another_account}
				</Button>
				<Alert severity="info" style={{ marginTop: "20px" }}>
					{t.demand_request_accepted_message}
				</Alert>
			</CardContent>
		</Card>
	);
};

export default Confirmation;
